import { Injectable, InjectionToken, Inject } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of, BehaviorSubject, throwError } from 'rxjs';
import { Member } from '../model/Member';
import { mergeMap, catchError, tap } from 'rxjs/operators';
import { AppConfig } from '../app.config';
import { Scenario, ScenarioData } from '../model/Scenario';
import { environment } from 'src/environments/environment';
import { MemberService } from './member.service';


export const SERVER_BASE_URL = new InjectionToken<string>('SERVER_BASE_URL');

const defaultHTTPOptions = {
  headers: {
    'Accept': 'application/json',
  },
  withCredentials: true,
};

@Injectable({
  providedIn: 'root'
})
export class DataManagerService {
  private baseUrl: string;
  private scenarioUrl: string;

  private memberId = new BehaviorSubject<number>(null);
  public memberId$ = this.memberId.asObservable();

  constructor(private http: HttpClient
    , @Inject(SERVER_BASE_URL) baseUrl: string) {

    this.baseUrl = baseUrl + '/api/Projection';
    this.scenarioUrl = baseUrl + '/api/Scenario';
  }

  // Fetch MemberData
  getDataFromAPI(isDefault: boolean,
    AnnualSalaryIncrease: number = null,
    RRSPbalance: number = null,
    RateOfReturn: number = null,
    ContributionIncrease: number = null,
    RRSPCont: boolean = null,
    AnnualContribution: number = null): Observable<Member> {


    if (AnnualSalaryIncrease > 0) {
      AnnualSalaryIncrease = AnnualSalaryIncrease / 100;
    }

    if (RateOfReturn > 0) {
      RateOfReturn = RateOfReturn / 100;
    }

    if (ContributionIncrease > 0) {
      ContributionIncrease = ContributionIncrease / 100;
    }


    const userInput = {
      'AnnualSalaryIncrease': isDefault ? AppConfig.settings.API_PARAMS.AnnualSalaryIncrease / 100 : AnnualSalaryIncrease,
      'CurrentRRSPBalance': isDefault ? AppConfig.settings.API_PARAMS.CurrentRRSPBalance : RRSPbalance,
      'RRSPRateOfReturn': isDefault ? AppConfig.settings.API_PARAMS.RRSPRateOfReturn / 100 : RateOfReturn,
      'AnnualRRSPContInc': isDefault ? AppConfig.settings.API_PARAMS.AnnualRRSPContInc / 100 : ContributionIncrease,
      'AssumeMaxRRSPCont': isDefault ? AppConfig.settings.API_PARAMS.AssumeMaxRRSPCont : RRSPCont,
      'AnnualRRSPCont': isDefault ? AppConfig.settings.API_PARAMS.AnnualRRSPCont : AnnualContribution
    };

    if (!environment.production) { console.log(userInput); }
    let dataApiUrl = this.baseUrl;
    if (environment.demo) { dataApiUrl += '?demoMemberId=' + this.getId(); }
    if (!environment.production) { console.log(dataApiUrl); }

    return this.http.post<Member | null>(dataApiUrl, userInput, defaultHTTPOptions).pipe(
      tap(member => { if (!environment.production) { console.log(member); } }),
      catchError((err: any) => {
        throw err;
      }));
  }

  // Fetch Scenario List
  getScenario(): Observable<Scenario[]> {
    return this.http.get<Scenario[]>(this.scenarioUrl, defaultHTTPOptions).pipe(
      catchError(err => throwError(err)));
  }

  // Delete Scenario
  deleteScenario(id: string) {
    return this.http.delete(this.scenarioUrl + '/' + id, defaultHTTPOptions).pipe(
      catchError(err => throwError(err)));
  }

  // Save Scenario
  saveScenario(param: ScenarioData) {
    const content = JSON.stringify(param);
    const postParam = { 'Data': content };
    return this.http.post<Scenario>(this.scenarioUrl, postParam, defaultHTTPOptions).pipe(
      tap(r => {
        if (!environment.production) { console.log(r); }
      }),
      catchError(err => {
        console.log(err);
        return throwError(err);
      }));
  }


  // parameter MemberId
  setId(data: number) {

    this.memberId.next(data);
  }

  getId(): number {
    return this.memberId.value;
  }
}
