import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Member } from '../model/Member';
import { DataManagerService } from '../service/data-manager.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Chart } from '../model/chart';


interface IAppState {
  chart: Chart;
  addedDate: any[];
  errorFlag: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class MemberService {

  private memberData: BehaviorSubject<Member>;
  public member$: Observable<Member>;

  constructor(private dataManagementService: DataManagerService) {
    this.memberData = new BehaviorSubject<Member>(null);
    this.member$ = this.memberData.asObservable();
  }


  private appState = new BehaviorSubject<IAppState>({
    chart: null,
    addedDate: null,
    errorFlag: false
  });

  // Chart Control Session
  private chartData = new BehaviorSubject<Chart>(null);
  public chart$ = this.chartData.asObservable();

  // Added Retirement date session
  private addedDate = new BehaviorSubject<any[]>([]);
  public addedDate$ = this.addedDate.asObservable();


  private errFlg = new BehaviorSubject(false);
  public isError$ = this.errFlg.asObservable();


  setMemberInfomation(param: Member) {

    this.memberData.next(param);

  }

  getMemberData() {

    return this.memberData.value;
  }

  isAPIError(): boolean {

    return this.errFlg.value;
  }


  // Chart Control Session
  setChart(data: Chart) {
    this.chartData.next(data);
  }

  getChart(): Chart {
    return this.chartData.value;
  }

  // Added Retirement date session
  setAddedDate(data: any[]) {

    this.addedDate.next(data);
  }

  getAddedDate(): any[] {
    return this.addedDate.value;
  }



}
