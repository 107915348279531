
export class Scenario {
    ScenarioID: string;
    Data: string;
}

export class ScenarioData {
    AnnualSalaryIncrease: number;
    showNet: boolean;
    PaymentOptions: number;
    IncludeProjection: boolean;
    RRSPbalance: number;
    RateOfReturn: number;
    RRSPCont: boolean;
    ContributionIncrease: number;
    AnnualContribution: number;
    AddedDate: any[];
    SavedDate: string;
    ScenarioName: string;
}

export class ScenarioJson {
    ScenarioID: string;
    Data: ScenarioData;
}
