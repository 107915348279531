import { Component, OnDestroy, OnInit, isDevMode } from '@angular/core';
import { FooterDialogComponent } from '../footer/footer';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { HeaderService } from '../header/header.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { MemberService } from '../service/member.service';
import { DataManagerService } from '../service/data-manager.service';
import { IBusyConfig } from 'ng-busy';
import { AssumptionDialogComponent } from '../dialog/assumpotionsDialog';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-home',
  templateUrl: './home.html',
  styleUrls: ['./home.scss']
})
export class HomeComponent implements OnInit, OnDestroy {

  busyConfig: IBusyConfig = { message: 'Loading...', backdrop: false };
  dataServiceSub: any;
  checked: boolean;
  showSpinner = false;
  getDataSub: any;
  bookletURL: string;
  noticeURL: string;
  // Subscriber instance
  transSub: any;
  paramSub: any;
  // parameter
  id: number;
  email: string;
  constructor(private translate: TranslateService,
    public dialog: MatDialog,
    private headerService: HeaderService,
    private dataManagerService: DataManagerService,
    private router: Router,
    private route: ActivatedRoute,
    private memberService: MemberService) {

    this.checked = this.headerService.getStatus();
  }

  openDialog(param: string): void {

    this.dialog.open(FooterDialogComponent, {
      width: '40%',
      height: '58%',
      panelClass: 'custom-dialog-container',
      data: { name: param },
      hasBackdrop: true
    });
  }

  openAssumptionDialog(): void {

    this.dialog.open(AssumptionDialogComponent, {
      width: '40%',
      height: '48%',
      panelClass: 'assumptionClass',
      hasBackdrop: true
    });
  }

  setLang(lang: string) {
    this.translate.use(lang);
  }

  ngOnInit(): void {

    this.transSub = this.translate.getTranslation(this.translate.currentLang).subscribe(trans => {
      this.bookletURL = trans.HOME.PATH + trans.HOME.LINK_BOOKLET;
      this.noticeURL = trans.HOME.PATH + trans.HOME.LINK_NOTICE;
      this.email = 'mailto: ' + trans.COMMON.EMAIL;
    });

    this.showSpinner = true;

    // tslint:disable-next-line: radix
    const did = parseInt(this.route.snapshot.queryParamMap.get('did'));

    if (!isNaN(did)) { this.dataManagerService.setId(did); }

    this.dataServiceSub = this.dataManagerService.getDataFromAPI(true).subscribe(d => {
      this.memberService.setMemberInfomation(d);
    }
      , (err: HttpErrorResponse) => {
        if (err.status === 403) {
          this.router.navigate(['error403']);
        } else {
          this.router.navigate(['error']);
        }

      });
    this.busyConfig.busy = [this.dataServiceSub];
  }


  openNav(): void {
    this.headerService.setOpen(true);
    this.checked = this.headerService.getStatus();
    this.headerService.setOpen(true);
    this.router.navigate(['your-plan']);

  }

  ngOnDestroy() {
    if (this.dataServiceSub) {
      this.dataServiceSub.unsubscribe();
    }
    if (this.getDataSub) {
      this.getDataSub.unsubscribe();
    }
    if (this.paramSub) {
      this.paramSub.unsubscribe();
    }
  }
}
