
import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';



@Component({
  selector: 'app-assumptions-dialog',
  templateUrl: './assumpotionsDialog.html',
})

export class AssumptionDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<AssumptionDialogComponent>) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
  // Close Button
  onClickClose(): void {
    this.dialogRef.close();
  }

}
