import { Injectable } from '@angular/core';

import {BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class HeaderService {

 // isOpen :boolean;

  constructor() { }

  private flg = new BehaviorSubject(false);
  currentFlg = this.flg.asObservable();

  setOpen(isOpen: boolean) {
    this.flg.next(isOpen);
  }

  getStatus(): boolean {
    return this.flg.value;
  }

}
