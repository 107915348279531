import { Component, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';



export interface DialogData {
  name: string;
}
@Component({
  templateUrl: './dialog.html',
})

export class FooterDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<FooterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
  // Close Button
  onClickClose(): void {
    this.dialogRef.close();
  }

}


@Component({
  selector: 'app-footer-component',
  templateUrl: './footer.html',
  styleUrls: ['./footer.scss']
})

export class FooterComponent {
  year: number;
  constructor(private translate: TranslateService, public dialog: MatDialog) {
    this.year = new Date().getFullYear();
   }

  openDialog(param: string): void {

    this.dialog.open(FooterDialogComponent, {
      width: '40%',
      height: '58%',
      panelClass: 'custom-dialog-container',
      data: { name: param },
      hasBackdrop: true
    });
  }

  setLang(lang: string) {
    this.translate.use(lang);
  }

}

