import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HeaderService } from '../header/header.service';
import { DataManagerService } from '../service/data-manager.service';

@Component({
  selector: 'app-header-component',
  templateUrl: './header.html',
  styleUrls: ['./header.scss']
})
export class HeaderComponent implements OnInit {
  isCollapsed;
  isDisabled: boolean;
  lang: string;
  constructor(private translate: TranslateService,
    private headerService: HeaderService) {
    this.isDisabled = true;
    this.lang = 'en';
  }

  setLang(e: any) {
    this.translate.use(e.value === 'en' ? 'en_CA' : 'fr_CA');
  }

  ngOnInit() {

    this.headerService.currentFlg.subscribe(flg => this.isDisabled = !flg);

  }

}
