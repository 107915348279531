import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home';
import { PlanComponent } from './plan/plan';
import { DeathComponent } from './death/death';
import { PensionComponent } from './pension/pension';
import { RetirementComponent } from './retirement/retirement';
import { TerminationComponent } from './termination/termination';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ErrorComponent } from './error/error.component';
import { Error403Component } from './error403/error403.component';

const routes: Routes = [
  { path: '', component: HomeComponent, pathMatch: 'full' },
  { path: 'your-plan', component: PlanComponent, pathMatch: 'full' },
  { path: 'death', component: DeathComponent, pathMatch: 'full' },
  { path: 'pension', component: PensionComponent, pathMatch: 'full' },
  { path: 'retirement', component: RetirementComponent, pathMatch: 'full' },
  { path: 'termination', component: TerminationComponent, pathMatch: 'full' },
  { path: 'error', component: ErrorComponent, pathMatch: 'full' },
  { path: 'error403', component: Error403Component, pathMatch: 'full' },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
