import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MemberService } from '../service/member.service';
import { TranslateService } from '@ngx-translate/core';
import { Stringifiable } from 'd3';


@Component({
  selector: 'app-pension',
  templateUrl: './pension.html',
  styleUrls: ['./pension.scss']
})
export class PensionComponent implements OnInit, OnDestroy {

  getDataSub: any;
  transSub: any;

  lnkApply: string;
  lnkCalc: string;
  lnkStatement: string;
  lnkDisBenf: string;
  lnkSrvBenf: string;
  lnkGIS: string;
  lnkAllowance: string;
  lnkSrvAllowance: string;

  constructor(private router: Router,
    private memberService: MemberService,
    private translate: TranslateService) { }

  ngOnInit(): void {
    this.transSub = this.translate.getTranslation(this.translate.currentLang).subscribe(trans => {
      this.lnkApply = trans.PENSION.LINK_APPLY;
      this.lnkCalc = trans.PENSION.LINK_CALC;
      this.lnkStatement = trans.PENSION.LINK_STATEMNENT;
      this.lnkDisBenf = trans.PENSION.LINK_DISBENF;
      this.lnkSrvBenf = trans.PENSION.LINK_SRVBENF;
      this.lnkGIS = trans.PENSION.LINK_GIS;
      this.lnkAllowance = trans.PENSION.LINK_ALLOWANCE;
      this.lnkSrvAllowance = trans.PENSION.LINK_SRVALLOWANCE;
    });

    // In case refresh page


    this.getDataSub = this.memberService.member$.subscribe(d => {
      if (d == null) {
        this.router.navigate(['']);
      }
    });

  }

  ngOnDestroy() {
    if (this.getDataSub) {
      this.getDataSub.unsubscribe();
    }
    if (this.transSub) {
      this.transSub.unsubscribe();
    }
  }
}
