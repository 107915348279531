import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MemberService } from '../service/member.service';

@Component({
  selector: 'app-death',
  templateUrl: './death.html',
  styleUrls: ['./death.scss']
})
export class DeathComponent implements OnInit, OnDestroy {

  getDataSub: any;

  constructor(private router: Router,
    private memberService: MemberService) { }

  ngOnInit(): void {
    // In case refresh page
    this.getDataSub = this.memberService.member$.subscribe(d => {
      if (d == null) {
        this.router.navigate(['']);
      }
    });

  }

  ngOnDestroy() {
    if (this.getDataSub) {
      this.getDataSub.unsubscribe();
    }
  }

}
