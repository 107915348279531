import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule, MAT_DATE_FORMATS, DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule, MatSpinner } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatGridListModule } from '@angular/material/grid-list';
import { FlexLayoutModule } from '@angular/flex-layout';
import { LayoutModule } from '@angular/cdk/layout';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { HomeComponent } from './home/home';
import { FooterComponent, FooterDialogComponent } from './footer/footer';
import { HeaderComponent } from './header/header';
import { PlanComponent } from './plan/plan';
import { DeathComponent } from './death/death';
import { PensionComponent } from './pension/pension';
import { RetirementComponent, MY_FORMATS } from './retirement/retirement';
import { TerminationComponent } from './termination/termination';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatMomentDateModule, MomentDateAdapter } from '@angular/material-moment-adapter';
import { JwtInterceptor } from './interceptor/interceptor';
import { SERVER_BASE_URL } from './service/data-manager.service';
import { environment } from '../environments/environment';
import { AppConfig } from './app.config';
import { NgBusyModule } from 'ng-busy';
import { SpinnerComponent } from './spinner/spinner.component';
import { ScenarioDialogComponent } from './retirement/scenario/scenarioControl';
import { ChartControlDialogComponent } from './retirement/chart/chartControl';
import { SidebarComponent } from './sidebar/sidebar.component';
import { ScenarioConfirmlDialogComponent } from './retirement/scenario/confirm/scenarioConfirm';
import { AgeDialogComponent } from './retirement/age/ageController';
import { AssumptionDialogComponent } from './dialog/assumpotionsDialog';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { NgxMaskModule } from 'ngx-mask';
import { ErrorComponent } from './error/error.component';
import { Error403Component } from './error403/error403.component';


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function initializeApp(appConfig: AppConfig) {
  return () => appConfig.load();
}



@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    FooterComponent,
    HeaderComponent,
    PlanComponent,
    DeathComponent,
    PensionComponent,
    RetirementComponent,
    TerminationComponent,
    FooterDialogComponent,
    SpinnerComponent,
    ChartControlDialogComponent,
    ScenarioDialogComponent,
    ScenarioConfirmlDialogComponent,
    AgeDialogComponent,
    SidebarComponent,
    AssumptionDialogComponent,
    PageNotFoundComponent
    , ErrorComponent, Error403Component
  ],
  imports: [
    BrowserModule,
    NgbModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgBusyModule.forRoot({
      template: SpinnerComponent
    }),
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatSidenavModule,
    MatTableModule,
    MatDialogModule,
    MatSlideToggleModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatGridListModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatNativeDateModule,
    MatIconModule,
    MatProgressSpinnerModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    LayoutModule,
    OverlayModule,
    NgxMaskModule.forRoot({})
  ],
  entryComponents: [FooterComponent,
    FooterDialogComponent,
    RetirementComponent,
    ChartControlDialogComponent,
    MatSpinner,
    SpinnerComponent,
    ScenarioDialogComponent,
    ScenarioConfirmlDialogComponent,
    AgeDialogComponent,
    AssumptionDialogComponent],
  providers: [{ provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: false } },
  { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
  { provide: SERVER_BASE_URL, useValue: environment.apiUrl },
  { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    AppConfig,
  {
    provide: APP_INITIALIZER,
    useFactory: initializeApp,
    deps: [AppConfig], multi: true
  }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
