
import { Component, Inject, EventEmitter, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Chart } from '../../model/chart';
import { DataManagerService } from '../../service/data-manager.service';
import { FormControl, Validators } from '@angular/forms';
import { AppConfig } from '../../app.config';

@Component({
  selector: 'app-chart-control',
  templateUrl: './chartControl.html',
})
export class ChartControlDialogComponent implements OnInit {

  chart: Chart = new Chart;

  AnnualSalaryIncrease;
  RRSPbalance;
  RateOfReturn;
  ContributionIncrease;
  AnnualContribution;
  chkRRSP;


  constructor(
    public dialogRef: MatDialogRef<ChartControlDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Chart,
    private dataManagerService: DataManagerService, ) {
    this.AnnualSalaryIncrease = new FormControl(data.AnnualSalaryIncrease,
      [Validators.max(AppConfig.settings.CHART.AnnualSalaryIncrease.MAX)
        , Validators.min(AppConfig.settings.CHART.AnnualSalaryIncrease.MIN)
        , Validators.required]);
    //    ,numericValidator(/^[0-9]+$/i)

    this.RRSPbalance = new FormControl(data.RRSPbalance, [Validators.max(AppConfig.settings.CHART.CurrentRRSPBalance.MAX),
    Validators.min(AppConfig.settings.CHART.CurrentRRSPBalance.MIN),
    Validators.required]);
    //    ,Validators.pattern('^[0-9]+$')

    this.RateOfReturn = new FormControl(data.RateOfReturn, [Validators.max(AppConfig.settings.CHART.RRSPRateOfReturn.MAX),
    Validators.min(AppConfig.settings.CHART.RRSPRateOfReturn.MIN),
    Validators.required]);
    this.ContributionIncrease = new FormControl(data.ContributionIncrease, [Validators.max(AppConfig.settings.CHART.AnnualRRSPContInc.MAX),
    Validators.min(AppConfig.settings.CHART.AnnualRRSPContInc.MIN),
    Validators.required]);
    this.AnnualContribution = new FormControl(data.AnnualContribution, [Validators.max(AppConfig.settings.CHART.AnnualRRSPCont.MAX),
    Validators.min(AppConfig.settings.CHART.AnnualRRSPCont.MIN),
    Validators.required]);

    if (this.data.RRSPCont === undefined) {
      this.data.RRSPCont = AppConfig.settings.CHART.AssumeMaxRRSPCont;
    }

  }


  onAdd = new EventEmitter();
  ngOnInit() {
    this.dialogRef.updatePosition({ top: '100px' });
  }

  changeRRSPCnt(e) {

    this.chkRRSP = e.target.checked;
    // if(this.chkRRSP){
    //   this.AnnualContribution.setValue(null);
    // }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }


  onSubmit(e: any): void {

  }

  onClickApplyToChart(f: any): void {
    this.setParams();
    // cannot pass this.data directly because only changed data is passed, not everything.  It needs to use this.chart.
    this.onAdd.emit(this.chart);
    this.dialogRef.close();
  }

  setParams() {
    this.chart.AnnualSalaryIncrease = (this.AnnualSalaryIncrease.value === null) ?
      AppConfig.settings.API_PARAMS.AnnualSalaryIncrease : this.AnnualSalaryIncrease.value;
    this.chart.RRSPbalance = (this.RRSPbalance.value === null) ?
      AppConfig.settings.API_PARAMS.CurrentRRSPBalance : this.RRSPbalance.value;
    this.chart.RateOfReturn = (this.RateOfReturn.value === null) ?
      AppConfig.settings.API_PARAMS.RRSPRateOfReturn : this.RateOfReturn.value;
    this.chart.ContributionIncrease = (this.ContributionIncrease.value === null) ?
      AppConfig.settings.API_PARAMS.AnnualRRSPContInc : this.ContributionIncrease.value;
    this.chart.AnnualContribution = (this.AnnualContribution.value === null) ?
      AppConfig.settings.API_PARAMS.AnnualRRSPCont : this.AnnualContribution.value;
    this.chart.PaymentOptions = this.data.PaymentOptions;
    this.chart.RRSPCont = this.data.RRSPCont;
    this.chart.IncludeProjection = this.data.IncludeProjection;
    this.chart.showNet = this.data.showNet;

  }

}


