import { Component, OnDestroy, OnInit } from '@angular/core';
import { MemberService } from '../service/member.service';
import { Member } from '../model/Member';
import { IBusyConfig } from 'ng-busy';
import { Router } from '@angular/router';
import { FooterDialogComponent } from '../footer/footer';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
@Component({
  selector: 'app-your-plan',
  templateUrl: './plan.html',
  styleUrls: ['./plan.scss']
})
export class PlanComponent implements OnInit, OnDestroy {
  busyConfig: IBusyConfig = { message: 'Loading...', backdrop: true };
  name: string;
  employeeId: string;
  hireDate: Date;
  membershipDate: Date;
  establishedService: number;
  continuousService: number;
  currentEarning: number;
  eRetirementDate: Date;
  nRetirementDate: Date;
  maritalStatus: string;
  spouse: string;
  dob: string;
  strDob: string;
  accruedPension: number;
  dateOfLastStatement: Date;
  color = 'warn';
  getDataSub: any;
  memberData: Member;
  calcURL: string;
  bookletURL: string;

  showSpinner = false;

  transSub: any;
  constructor(private translate: TranslateService,
    private memberService: MemberService,
    private router: Router,
    public dialog: MatDialog
  ) { }


  ngOnInit(): void {
    this.transSub = this.translate.getTranslation(this.translate.currentLang).subscribe(trans => {
      this.calcURL = trans.HOME.PATH + trans.HOME.LINK_NOTICE;
      this.bookletURL = trans.HOME.PATH + trans.HOME.LINK_BOOKLET;
    });

    this.getDataSub = this.memberService.member$.subscribe(d => {

      if (d == null) {
        // In case refresh page
        this.router.navigate(['']);

      } else {
        this.memberData = d;

        this.setData();

      }

    });


  }

  private setData(): void {
    this.transSub = this.translate.getTranslation(this.translate.currentLang).subscribe(trans => {
      this.name = this.memberData.FullName;
      this.employeeId = this.memberData.EmployeeId;
      this.hireDate = new Date(this.memberData.DateOfHire);
      this.membershipDate = new Date(this.memberData.DateOfMembership);
      this.establishedService = this.memberData.PensionableService;
      this.continuousService = this.memberData.EligibilityService;
      this.currentEarning = this.memberData.CurrentTotalAnnualEarnings;
      this.eRetirementDate = new Date(this.memberData.EURD);
      this.nRetirementDate = new Date(this.memberData.NRD);
      this.maritalStatus = this.memberData.MaritalStaus === 0 ? trans.PLAN.SINGLE : trans.PLAN.MARRIED;
      this.spouse = (!this.memberData.HasSpouse) ? trans.PLAN.NOR : this.memberData.SpouseFullName;
      this.dob = (this.memberData.SpouseDateOfBirth == null) ? trans.PLAN.NA : moment(this.memberData.SpouseDateOfBirth).format('ll');
      this.accruedPension = this.memberData.AccruedPensionOnLastStatement;
      this.dateOfLastStatement = new Date(this.memberData.DateOfLastStatement);
    });
  }


  ngOnDestroy() {
    if (this.getDataSub) {
      this.getDataSub.unsubscribe();
    }
    if (this.transSub) {
      this.transSub.unsubscribe();
    }
  }

  openDialog(param: string): void {

    this.dialog.open(FooterDialogComponent, {
      width: '40%',
      height: '55%',
      panelClass: 'custom-dialog-container',
      data: { name: param },
      hasBackdrop: true
    });
  }

}
