
import { Component, Inject, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RetirementBenefits } from 'src/app/model/RetirementBenefits';
import { RetirementEvents } from 'src/app/model/RetirementEvents';
import { MemberService } from 'src/app/service/member.service';

@Component({
  selector: 'app-age-controller',
  templateUrl: './ageController.html',
})

export class AgeDialogComponent {

  onAdd = new EventEmitter();
  RetirementDate;
  dateForAdd: any[] = [];
  constructor(
    public dialogRef: MatDialogRef<AgeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public ageData: RetirementEvents,
    private memberService: MemberService) { }



  // Close Button
  onClickClose(): void {
    this.dialogRef.close();
  }

  // Add Button
  onAddDate(): void {

    this.dateForAdd.push(this.ageData.RetirementDate);
    this.memberService.setAddedDate(this.dateForAdd);
    this.onAdd.emit();
    this.dialogRef.close();
  }
}
