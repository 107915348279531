import { ApplicableFormsOfPension } from './ApplicableFormsOfPension';
export class Chart {
    AnnualSalaryIncrease: number;
    showNet: boolean;
    PaymentOptions: number;
    IncludeProjection: boolean;
    RRSPbalance: number;
    RateOfReturn: number;
    ContributionIncrease: number;
    RRSPCont: boolean;
    AnnualContribution: number;
    FormsOfPension: ApplicableFormsOfPension[];
}
