import { Component, Inject, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Chart } from '../../model/chart';
import { DataManagerService } from '../../service/data-manager.service';
import { Scenario, ScenarioData, ScenarioJson } from '../../model/Scenario';
import { AppConfig } from '../../app.config';
import { FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { MemberService } from '../../service/member.service';
import { ScenarioConfirmlDialogComponent } from './confirm/scenarioConfirm';
@Component({
  selector: 'app-scenario-control',
  templateUrl: './scenarioControl.html',
  styleUrls: ['../retirement.scss']
})

export class ScenarioDialogComponent implements OnInit, OnDestroy {

  public data: any;
  public initialPage: Object;
  private chart: Chart = new Chart;
  onAdd = new EventEmitter();


  tblColumns: string[] = ['scenario', 'savedDate', 'applyChart', 'delete'];
  scenarioName = new FormControl('', []);

  // subscriber instance
  getSub: any;
  saveSub: any;
  deleteSub: any;
  constructor(
    public dialogRef: MatDialogRef<ScenarioDialogComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public chartData: Chart,
    private translate: TranslateService,
    private memberService: MemberService,
    private dataManagerService: DataManagerService) {

  }


  ngOnInit() {

    this.dialogRef.updatePosition({ top: '100px' });
    this.getScenarioList();

  }

  // Apply to chart
  onApply(rowDt, event) {
    //  event.stopPropagation();
    const scenarioData: ScenarioJson = rowDt;
    this.setChart(scenarioData.Data);
    this.onAdd.emit(this.chart);
    this.dialogRef.close();
  }

  // Delet Scenario
  onDelete(rowDt, event) {
    event.stopPropagation();

    this.deleteSub = this.dataManagerService.deleteScenario(rowDt.ScenarioID).subscribe(d => {

      this.getScenarioList();

    },
      err => { console.log(err); }
    );
  }


  ngOnDestroy() {

    if (this.getSub) {
      this.getSub.unsubscribe();
    }

    if (this.saveSub) {
      this.saveSub.unsubscribe();
    }

    if (this.deleteSub) {
      this.deleteSub.unsubscribe();
    }

  }


  getScenarioList() {
    this.getSub = this.dataManagerService.getScenario().subscribe(d =>
      this.data = this.convertJSONFormatClass(d), err => { console.log(err); });
  }


  setSenarioDataForSave(): ScenarioData {
    const param: ScenarioData = new ScenarioData;
    param.AnnualContribution = this.chartData.AnnualContribution;
    param.AnnualSalaryIncrease = this.chartData.AnnualSalaryIncrease;
    param.ContributionIncrease = this.chartData.ContributionIncrease;
    param.IncludeProjection = !this.chartData.IncludeProjection ? false : this.chartData.IncludeProjection;
    param.PaymentOptions = this.chartData.PaymentOptions;
    param.RRSPCont = !this.chartData.RRSPCont ? AppConfig.settings.API_PARAMS.AssumeMaxRRSPCont : this.chartData.RRSPCont;
    param.RRSPbalance = this.chartData.RRSPbalance;
    param.RateOfReturn = this.chartData.RateOfReturn;
    param.showNet = !this.chartData.showNet ? false : this.chartData.showNet;
    param.SavedDate = moment(new Date()).format('lll');
    param.ScenarioName = this.scenarioName.value;
    param.AddedDate = !this.memberService.getAddedDate() ? [] : this.memberService.getAddedDate();

    return param;
  }


  setChart(scenarioData: ScenarioData): void {
    this.chart.AnnualSalaryIncrease = scenarioData.AnnualSalaryIncrease;
    this.chart.RRSPbalance = scenarioData.RRSPbalance;
    this.chart.RateOfReturn = scenarioData.RateOfReturn;
    this.chart.ContributionIncrease = scenarioData.ContributionIncrease;
    this.chart.AnnualContribution = scenarioData.AnnualContribution;
    this.chart.PaymentOptions = scenarioData.PaymentOptions;
    this.chart.RRSPCont = scenarioData.RRSPCont;
    this.chart.IncludeProjection = scenarioData.IncludeProjection;
    this.chart.showNet = scenarioData.showNet;
    this.memberService.setAddedDate(scenarioData.AddedDate);

  }



  // string format(API format) to JSON format : API("Data":string) -> JSON
  convertJSONFormatClass(d: Scenario[]): ScenarioJson[] {
    const ret: Scenario[] = d;
    const arryScenario: ScenarioJson[] = [];
    ret.forEach(currentItem => {
      const wk: ScenarioJson = new ScenarioJson;
      wk.ScenarioID = currentItem.ScenarioID;
      wk.Data = JSON.parse(currentItem.Data);
      arryScenario.push(wk);
    });
    return arryScenario;
  }

  // --- save -----
  onClickSave(): void {

    const DataForSave: ScenarioData = this.setSenarioDataForSave();

    this.saveSub = this.dataManagerService.saveScenario(DataForSave).subscribe(d => {
      this.getScenarioList();

      const dialogRef = this.dialog.open(ScenarioConfirmlDialogComponent,
        {
          width: '250px',
          height: '250px',
          panelClass: 'scenarioConfirm',
          //  data:this.memberService.getChart(),
          hasBackdrop: true,
          disableClose: false
        });
    },
      err => { console.log(err); }
    );

  }


  // Close Button
  onClickClose(): void {
    this.dialogRef.close();
  }

}

