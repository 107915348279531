import { Component, ElementRef, Inject, ViewChild, OnInit, EventEmitter, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as d3 from 'd3';
import { TranslateService } from '@ngx-translate/core';
import { MemberService } from '../service/member.service';
import { Member } from '../model/Member';
import { Chart } from '../model/chart';
import { RetirementEvents } from '../model/RetirementEvents';
import { RetirementBenefits } from '../model/RetirementBenefits';
import { ApplicableFormsOfPension } from '../model/ApplicableFormsOfPension';
import * as moment from 'moment';
import { HeaderService } from '../header/header.service';
import { DataManagerService } from '../service/data-manager.service';
import { MonthlyTbl } from '../model/MonthlyTbl';
import { FormControl, Validators, ValidatorFn } from '@angular/forms';
import { AppConfig } from '../app.config';
import { IBusyConfig } from 'ng-busy';
import { ScenarioDialogComponent } from './scenario/scenarioControl';
import { ChartControlDialogComponent } from './chart/chartControl';
import { Router } from '@angular/router';
import { AgeDialogComponent } from './age/ageController';
import { AssumptionDialogComponent } from '../dialog/assumpotionsDialog';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  }
  ,
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

// JS function in Common.js
declare var openSideBar: Function;

// Bar Chart Color Setting
const COLOR_RPP = 'coral';
const COLOR_BRIDGE = '#b8b894';

// const COLOR_SERP : string = "#EBEB75";
// const COLOR_CPP : string = "lightseagreen";
// const COLOR_OAS : string = "#80E0A8";
// const COLOR_RRSP : string = "#C8E6D4";
// const COLOR_TOTAL : string = "#837DFF";
// const COLOR_TAX : string = "#32DBC6";
const COLOR_SERP = 'lightseagreen';
const COLOR_CPP = '#79D4C3';
const COLOR_OAS = '#D4ED7B';
const COLOR_RRSP = '#F7E574';
const COLOR_TOTAL = 'coral';
const COLOR_TAX = '#F2DDDA';



const FORM_COL = 'form';
const SURVIVOR_COL = 'survivor';
const PRE65_COL = 'pre65';
const POST65_COL = 'post65';


const RETDATE_COL = 'retirementDate';
const RETAGE_COL = 'retirementAge';
const EMP_COL = 'empColumn';
const TAX_COL = 'tax';
const RPP_COL = 'rpp';
const BRIDGE_COL = 'bridge';
const SERP_S_COL = 'serp';
const RRSP_S_COL = 'rrsp';
const CPP_S_COL = 'cpp';
const OAS_S_COL = 'oas';
const TOTAL_COL = 'total';


// Retirement Type
const RET_TYPE = { REDUCED: 0, UNREDUCED: 1, NORMAL: 2, MAXI: 3, OTHER: 4 };
const NA = 'N/A';


@Component({
  selector: 'app-retirement',
  templateUrl: './retirement.html',
  styleUrls: ['./retirement.scss']
})


// AfterContentInit
export class RetirementComponent implements OnInit, OnDestroy {

  @ViewChild('graph', { static: true })

  chartContainer: ElementRef;
  monthlyPension: RetirementEvents[];
  retirementEvents: RetirementEvents[];
  retirementBenefits: RetirementBenefits[] = [];
  icludesPre65: boolean;
  applicableFormsOfPension: ApplicableFormsOfPension[] = [];
  testData: Member;
  displayDetailColumns: string[] = [FORM_COL, POST65_COL];
  monthlyColumns: string[];   // check order as well in setMonthlyColumn function
  dateAssuming: string;
  dateFilter;
  addDate = new FormControl('');

  addedDateArray = [];
  colspanDetail = 1;

  annualEarning: number;
  showNet: boolean;
  PaymentOptions: number;
  IncludeProjection: boolean;
  RRSPbalance: number;
  RateOfReturn: number;
  ContributionIncrease: number;
  RRSPCont: boolean;
  AnnualContribution: number;
  chartControl: Chart = new Chart;
  memberData: Member;
  spans = [];
  prevChart: Chart = new Chart;

  monthlyData: MonthlyTbl[];
  DATA1: any[];
  colspanM = 5;

  entCnt: number;      // enter Count
  RET_TYPE_NAME: any;  // retirement type name
  RECT: any;
  CHART_COL: any;

  // Subscriber instance
  transSub: any;
  dialogSub: any;
  dataManagerSub: any;
  busyConfig: IBusyConfig = { message: 'Loading...', backdrop: false, wrapperClass: 'ng-busy' };

  showSpinner = false;    // for sipnner
  isFirstOpen = true;     // check controller open or not

  email: string;   // Footer email

  // isGovernment:boolean // CPP/OAS is ON or OFF

  // previous input
  prevRRSPCont = AppConfig.settings.API_PARAMS.AssumeMaxRRSPCont;
  prevAnnualSalary = AppConfig.settings.API_PARAMS.AnnualSalaryIncrease;
  prevRRSPBalance = AppConfig.settings.API_PARAMS.CurrentRRSPBalance;
  prevRRSPOfReturn = AppConfig.settings.API_PARAMS.RRSPRateOfReturn;
  prevAnnualContribution = AppConfig.settings.API_PARAMS.AnnualRRSPCont;
  preContributionIncrease = AppConfig.settings.API_PARAMS.AnnualRRSPContInc;

  constructor(public dialog: MatDialog,
    private translate: TranslateService,
    private memberService: MemberService,
    private dataManagerService: DataManagerService,
    private router: Router) {
  }

  ngOnInit(): void {
    this.transSub = this.translate.getTranslation(this.translate.currentLang).subscribe(trans => {

      this.setConst(trans);

      const d = this.memberService.getMemberData();


      if (d == null) {

        // In case refresh page
        this.router.navigate(['']);

      } else {

        this.initialize();
      }

    });

  }

  ngOnDestroy() {

    if (this.transSub) {
      this.transSub.unsubscribe();
    }
    if (this.dialogSub) {
      this.dialogSub.unsubscribe();
    }
    if (this.dataManagerSub) {
      this.dataManagerSub.unsubscribe();
    }

  }



  initialize(isFirst = true) {
    if (isFirst) {
      this.setDisplayData();
    }
    this.setMonthlyPension();
    this.createMonthlyTbl();
    this.setMonthlyColumn();
    this.setDetailTblColumn();    // Form of pension table column
    this.createBarChart(this.memberData.IsSERPEligible,
      this.memberData.HasSpouse,
      this.memberData.HasRRSPPension,
      this.memberData.IsYoungerThan65);
  }


  setDisplayData() {

    this.memberData = this.memberService.getMemberData();
    this.applicableFormsOfPension = this.memberData.ApplicableFormsOfPension;

    // Set default FormsOfPension
    if (this.memberService.getChart() == null) {
      // !postback
      this.chartControl.PaymentOptions = this.memberData.ApplicableFormsOfPension.filter(d => d.IsNormalForm)[0].FormID;

      // government default setting
      this.chartControl.IncludeProjection = true;
      // this.isGovernment = this.chartControl.IncludeProjection;

    } else {
      // postback
      this.chartControl = this.memberService.getChart();
    }

    // Set Min Max for DateControl
    this.setDateMinMax(this.memberData.MinRetirementDate, this.memberData.MaxRetirementDate);

    // Get default table data
    this.monthlyPension = this.memberData.RetirementEvents.filter(d => d.RetirementDateType !== RET_TYPE.OTHER);

    // Set entry count for display
    this.entCnt = 5 - this.monthlyPension.length;
    this.entCnt = this.entCnt > 2 ? 2 : this.entCnt;

  }

  setConst(trans: any) {

    // Retirement Type Name
    this.RET_TYPE_NAME = {
      REDUCED: trans.RETIREMENT.RET_TYPE_0
      , UNREDUCED: trans.RETIREMENT.RET_TYPE_1
      , NORMAL: trans.RETIREMENT.RET_TYPE_2
      , MAXI: trans.RETIREMENT.RET_TYPE_3
      , OTHER: trans.RETIREMENT.RET_TYPE_4
    };

    // bar chart wording
    this.RECT = {
      CHART_COL1: trans.RETIREMENT.CHART_COL1,
      CHART_COL2: trans.RETIREMENT.CHART_COL2,
      CHART_COL3: trans.RETIREMENT.CHART_COL3,
      CHART_COL4: trans.RETIREMENT.CHART_COL4,
      CHART_COL5: trans.RETIREMENT.CHART_COL5,
      CHART_COL6: trans.RETIREMENT.CHART_COL6,
      CHART_COL7: trans.RETIREMENT.CHART_COL7,
      CHART_COL8: trans.RETIREMENT.CHART_COL8
    };

    // bar chart column
    this.CHART_COL = {
      RPP: trans.RETIREMENT.TBL_COL4,
      BRIDGE: trans.RETIREMENT.TBL_COL5,
      SERP: trans.RETIREMENT.TBL_COL9,
      CPP: trans.RETIREMENT.TBL_COL10,
      OAS: trans.RETIREMENT.TBL_COL11,
      RRSP: trans.RETIREMENT.TBL_COL16,
      TOTAL: trans.RETIREMENT.TBL_COL18,
      TAX: trans.RETIREMENT.TBL_COL17
    };
    this.email = 'mailto: ' + trans.RETIREMENT.FOOTER_EMAIL;
  }

  getRowSpan(col, index) {
    return this.spans[index] && this.spans[index][col];
  }

  cacheSpan2(key, accessor) {
    for (let i = 0; i < this.DATA1.length;) {
      const currentValue = accessor(this.DATA1[i]);
      let count = 1;

      // Iterate through the remaining rows to see how many match
      // the current value as retrieved through the accessor.
      for (let j = i + 1; j < this.DATA1.length; j++) {
        if (currentValue !== accessor(this.DATA1[j])) {
          break;
        }

        count++;
      }

      if (!this.spans[i]) {
        this.spans[i] = {};
      }

      // Store the number of similar values that were found (the span)
      // and skip i to the next unique row.
      this.spans[i][key] = count;
      i += count;
    }
  }


  getRetirementTypeName(rType: number): string {

    if (rType === RET_TYPE.REDUCED) {
      return this.RET_TYPE_NAME.REDUCED;
    } else if (rType === RET_TYPE.UNREDUCED) {
      return this.RET_TYPE_NAME.UNREDUCED;
    } else if (rType === RET_TYPE.NORMAL) {
      return this.RET_TYPE_NAME.NORMAL;
    } else if (rType === RET_TYPE.MAXI) {
      return this.RET_TYPE_NAME.MAXI;
    } else {
      return this.RET_TYPE_NAME.OTHER;
    }
  }

  // RPP for table
  setRPPArray(benefits: RetirementBenefits[], p1: boolean, p2: boolean): string[] {

    return [p1 ? this.convertCurrency(benefits[0].RPPBenefitPre65) : NA,
    p2 ? this.convertCurrency(benefits[0].RPPBenefitPost65) : NA];
  }

  // Bridge for table
  setBridgeArray(benefits: RetirementBenefits[], p1: boolean, p2: boolean): string[] {
    const value = this.convertCurrency(benefits[0].Bridge);
    return [p1 ? value : NA, p2 ? value : NA];
  }

  // SERP for table
  setSERPArray(benefits: RetirementBenefits[], p1: boolean, p2: boolean): string[] {
    const value = this.convertCurrency(benefits[0].SERPBenefit);
    return [p1 ? value : NA, p2 ? value : NA];
  }


  // CPP for table
  setCPPArray(benefits: RetirementBenefits[]): string[] {
    return [NA, this.convertCurrency(benefits[0].CPPAt65)];
  }

  // OAS for table
  setOASArray(benefits: RetirementBenefits[]): string[] {
    return [NA, this.convertCurrency(benefits[0].OASAt65)];
  }

  // RRSP for table
  setRRSPArray(benefits: RetirementBenefits[], p1: boolean, p2: boolean): string[] {
    const value = this.convertCurrency(benefits[0].RRSPPension);
    return [p1 ? value : NA, p2 ? value : NA];
  }


  // need to convert .ts file because N/A is string
  convertCurrency(param: number): string {
    return param.toLocaleString('en-CA', {
      style: 'currency',
      currency: 'CAD',
    }).slice(0, -3);
  }

  // Total for table
  setTotalArray(benefits: RetirementBenefits[], p1: boolean): string[] {
    let valuePre;
    let valuePost;

    if (this.chartControl.showNet && this.chartControl.IncludeProjection) {
      // showNet:ON  CPP/OAS:ON
      valuePre = this.convertCurrency(benefits[0].TotalPRGPre65Net);
      valuePost = this.convertCurrency(benefits[0].TotalPRGPost65Net);
    } else if (this.chartControl.showNet && !this.chartControl.IncludeProjection) {
      // showNet:ON  CPP/OAS:OFF
      valuePre = this.convertCurrency(benefits[0].TotalPRPre65Net);
      valuePost = this.convertCurrency(benefits[0].TotalPRPost65Net);
    } else if (!this.chartControl.showNet && this.chartControl.IncludeProjection) {
      // showNet:OFF  CPP/OAS:ON
      valuePre = this.convertCurrency(benefits[0].TotalPRGPre65Gross);
      valuePost = this.convertCurrency(benefits[0].TotalPRGPost65Gross);
    } else {
      // showNet:OFF  CPP/OAS:OFF
      valuePre = this.convertCurrency(benefits[0].TotalPRPre65Gross);
      valuePost = this.convertCurrency(benefits[0].TotalPRPost65Gross);
    }

    return [p1 ? valuePre : NA, valuePost];

  }

  // Tax Payable table
  setTaxArray(benefits: RetirementBenefits[], isIncludesPre65: boolean): string[] {

    let taxPre;
    let taxPost;

    if (this.chartControl.showNet && this.chartControl.IncludeProjection) {
      // CPP/OAS:ON
      taxPre = this.convertCurrency(benefits[0].TaxPRGPre65);
      taxPost = this.convertCurrency(benefits[0].TaxPRGPost65);

    } else if (this.chartControl.showNet && !this.chartControl.IncludeProjection) {
      //  CPP/OAS:OFF
      taxPre = this.convertCurrency(benefits[0].TaxPRPre65);
      taxPost = this.convertCurrency(benefits[0].TaxPRPost65);

    }

    return isIncludesPre65 ? [taxPre, taxPost] : [NA, taxPost];
  }

  createMonthlyTbl(): void {

    let tempData: MonthlyTbl[] = [];

    let formId = this.memberData.ApplicableFormsOfPension.filter(e => e.IsNormalForm)[0].FormID;
    if (this.chartControl.PaymentOptions !== formId) {
      formId = this.chartControl.PaymentOptions;
    }

    const monthlyArray = [];
    this.monthlyPension.forEach(e => {
      const benefits = e.RetirementBenefits.filter(d => d.FormOfPension === formId);


      const aryRPP = [e.IncludesPre65 ?
        this.convertCurrency(benefits[0].RPPBenefitPre65) :
        NA, this.convertCurrency(benefits[0].RPPBenefitPost65)];
      const aryBridge = [e.IncludesPre65 ? this.convertCurrency(benefits[0].Bridge) : NA, NA];
      const arySERP = [e.IncludesPre65 ? this.convertCurrency(benefits[0].SERPBenefit) : NA, this.convertCurrency(benefits[0].SERPBenefit)];
      const aryRRSP = [e.IncludesPre65 ? this.convertCurrency(benefits[0].RRSPPension) : NA, this.convertCurrency(benefits[0].RRSPPension)];
      const aryCPP = this.setCPPArray(benefits);
      const aryOAS = this.setOASArray(benefits);

      const aryTotal = this.setTotalArray(benefits, e.IncludesPre65);
      const aryTax = this.setTaxArray(benefits, e.IncludesPre65);

      const monthlyRow: MonthlyTbl = {
        retirementDate: [moment(e.RetirementDate).format('ll'), this.getRetirementTypeName(e.RetirementDateType)],
        retirementAge: e.RetirementAge.toFixed(2),
        empColumn: ['Pre 65', 'Post 65'],
        rpp: aryRPP,
        bridge: aryBridge,
        serp: arySERP,
        rrsp: aryRRSP,
        cpp: aryCPP,
        oas: aryOAS,
        tax: aryTax,
        total: aryTotal,
        includesPre65: e.IncludesPre65
      };

      monthlyArray.push(monthlyRow);

    });

    this.DATA1 = [];
    tempData = monthlyArray;
    this.DATA1 = tempData.reduce((current, next) => {
      for (let index = 0; index < 2; index++) {
        current.push({
          retirementDate: next.retirementDate[index],
          retirementAge: next.retirementAge,
          empColumn: next.empColumn[index],
          rpp: next.rpp[index],
          bridge: next.bridge[index],
          serp: next.serp[index],
          rrsp: next.rrsp[index],
          cpp: next.cpp[index],
          oas: next.oas[index],
          tax: next.tax[index],
          total: next.total[index],
          includesPre65: next.includesPre65
        });

      }

      return current;
    }, []);
    this.monthlyData = this.DATA1;
    this.cacheSpan2('retirementAge', d => d.retirementAge);  // for row span
  }

  //   createMonthlyTbl():void{

  //     var tempData:monthlyTbl[] = [];

  //     var formId = this.memberData.ApplicableFormsOfPension.filter(e=>e.IsNormalForm)[0].FormID;
  //     if (this.chartControl.PaymentOptions != formId){
  //       formId = this.chartControl.PaymentOptions
  //     }

  //     let monthlyArray=[];
  //     this.monthlyPension.forEach(e=>{
  //     let benefits = e.RetirementBenefits.filter(d=>d.FormOfPension==formId);
  //     let aryRPP = [NA,NA];
  //     let aryBridge = [NA,NA];
  //     let arySERP = [NA,NA];
  //     let aryRRSP = [NA,NA];
  //     let aryCPP = this.setCPPArray(benefits);
  //     let aryOAS = this.setOASArray(benefits);
  //     let aryTotal = this.setTotalArray(benefits,true);
  //     let aryTax = this.setTaxArray(benefits)

  //     if ((e.RetirementDateType == RET_TYPE.REDUCED) || (e.RetirementDateType == RET_TYPE.UNREDUCED)){

  //       //Early Retirement- reduced & Early Retirement- UnReduced
  //       aryRPP = this.setRPPArray(benefits,true,true);
  //       aryBridge = this.setBridgeArray(benefits,true,false);
  //       arySERP = this.setSERPArray(benefits,true,true);
  //       aryRRSP = this.setRRSPArray(benefits,true,true);


  //     }else if((e.RetirementDateType == RET_TYPE.NORMAL) || (e.RetirementDateType == RET_TYPE.MAXI)){

  //       // Normal Retirment & Mximum
  //       aryRPP = this.setRPPArray(benefits,false,true);
  //       aryBridge = this.setBridgeArray(benefits,false,false);
  //       arySERP = this.setSERPArray(benefits,false,true);
  //       aryTotal = this.setTotalArray(benefits,false);
  //       aryRRSP = this.setRRSPArray(benefits,false,true);

  //     }else{

  //       aryRPP = this.setRPPArray(benefits,true,true);
  //       aryBridge = this.setBridgeArray(benefits,true,false);
  //       arySERP = this.setSERPArray(benefits,true,true);
  //       aryRRSP = this.setRRSPArray(benefits,true,true);

  //     }

  //     let monthlyRow: MonthlyTbl ={
  //       retirementDate:[moment(e.RetirementDate).format('ll'), this.getRetirementTypeName(e.RetirementDateType)],
  //       retirementAge: e.RetirementAge.toFixed(2),
  //       empColumn: ["Pre 65", "Post 65"],
  //       rpp:aryRPP,
  //       bridge:aryBridge,
  //       serp:arySERP,
  //       rrsp:aryRRSP,
  //       cpp:aryCPP,
  //       oas:aryOAS,
  //       tax:aryTax,
  //       total:aryTotal
  //     }

  //     monthlyArray.push(monthlyRow)

  //   });

  //   this.DATA1 =[];
  //   tempData = monthlyArray;
  //   this.DATA1 = tempData.reduce((current, next) => {
  //     for (let index = 0; index < 2; index++) {
  //       current.push({ retirementDate: next.retirementDate[index],
  //                       retirementAge: next.retirementAge,
  //                       empColumn: next.empColumn[index],
  //                       rpp:next.rpp[index],
  //                       bridge:next.bridge[index],
  //                       serp:next.serp[index],
  //                       rrsp:next.rrsp[index],
  //                       cpp:next.cpp[index],
  //                       oas:next.oas[index],
  //                       tax:next.tax[index],
  //                       total:next.total[index]
  //                    })

  //     }

  //     return current;
  //   }, []);
  //   this.monthlyData = this.DATA1;
  //   this.cacheSpan2('retirementAge', d=>d.retirementAge);  // for row span
  // }


  // Add your prefered retirement date button
  addRetirementDate(): void {

    const dtCnt = this.memberData.RetirementEvents.filter(d => (d.RetirementDateType !== RET_TYPE.OTHER)).length;   // original data count

    if (dtCnt === 4) {
      if (this.addedDateArray.length === 1) {
        this.addedDateArray.shift();
        this.addedDateArray.push(this.addDate.value);
      } else {
        this.addedDateArray.push(this.addDate.value);
      }
    } else {
      if (this.addedDateArray.length === 2) {
        this.addedDateArray.shift();
        this.addedDateArray.push(this.addDate.value);
      } else {
        this.addedDateArray.push(this.addDate.value);
      }
    }

    // Store in Session
    this.memberService.setAddedDate(this.addedDateArray);

    this.initialize(false);


  }

  setMonthlyPension() {

    let dateArray: any[] = [];

    if (this.addedDateArray.length < 1) {
      // From Session Data
      dateArray = this.memberService.getAddedDate();
    } else {
      // When no Session Data
      dateArray = this.addedDateArray;
    }

    if (dateArray.length === 2) {
      this.monthlyPension = this.memberData.RetirementEvents.filter(d => ((d.RetirementDateType !== RET_TYPE.OTHER)
        || (d.RetirementDateType === 4 && this.compareDate(d.RetirementDate, dateArray[0]))
        || (d.RetirementDateType === 4 && this.compareDate(d.RetirementDate, dateArray[1]))));

    } else if (dateArray.length === 1) {
      this.monthlyPension = this.memberData.RetirementEvents.filter(d => ((d.RetirementDateType !== RET_TYPE.OTHER)
        || (d.RetirementDateType === 4 && this.compareDate(d.RetirementDate, dateArray[0]))));

    } else {
      this.monthlyPension = this.memberData.RetirementEvents.filter(d => (d.RetirementDateType !== RET_TYPE.OTHER));
    }

  }


  compareDate(param1: string, param2: Date): boolean {
    const p1 = new Date(param1);
    const p2 = new Date(param2);
    let ret = p1.getFullYear() === p2.getFullYear();
    ret = ret && p1.getMonth() === p2.getMonth();
    ret = ret && p1.getDate() === p2.getDate();

    return ret;
  }


  checkIsDefaultValue(): boolean {

    return ((this.chartControl.AnnualContribution === AppConfig.settings.API_PARAMS.AnnualRRSPCont) &&
      (this.chartControl.AnnualSalaryIncrease === AppConfig.settings.API_PARAMS.AnnualSalaryIncrease) &&
      (this.chartControl.ContributionIncrease === AppConfig.settings.API_PARAMS.AnnualRRSPContInc) &&
      (this.chartControl.RRSPbalance === AppConfig.settings.API_PARAMS.CurrentRRSPBalance) &&
      (this.chartControl.AnnualSalaryIncrease === AppConfig.settings.API_PARAMS.AnnualSalaryIncrease) &&
      (this.chartControl.RRSPCont === AppConfig.settings.API_PARAMS.AssumeMaxRRSPCont)) ? false : true;
  }



  defineChartControl() {

    if (this.memberService.getChart() == null) {

      this.chartControl.FormsOfPension = this.applicableFormsOfPension;
      this.chartControl.PaymentOptions = this.applicableFormsOfPension.filter(d => d.IsNormalForm)[0].FormID;

      this.chartControl.RRSPCont = AppConfig.settings.API_PARAMS.AssumeMaxRRSPCont;
      this.chartControl.AnnualSalaryIncrease = AppConfig.settings.API_PARAMS.AnnualSalaryIncrease;
      this.chartControl.RRSPbalance = AppConfig.settings.API_PARAMS.CurrentRRSPBalance;
      this.chartControl.RateOfReturn = AppConfig.settings.API_PARAMS.RRSPRateOfReturn;
      this.chartControl.AnnualContribution = AppConfig.settings.API_PARAMS.AnnualRRSPCont;
      this.chartControl.ContributionIncrease = AppConfig.settings.API_PARAMS.AnnualRRSPContInc;
      this.chartControl.showNet = false;
      this.memberService.setChart(this.chartControl);

    } else {

      this.chartControl = this.memberService.getChart();
      this.chartControl.FormsOfPension = this.applicableFormsOfPension;

    }
  }


  openAgeDialog(): void {


    const ageDialog = this.dialog.open(AgeDialogComponent,
      {
        width: '295px',
        height: '350px',
        data: this.memberData.RetirementEvents,
        panelClass: 'ageControllerClass',
        hasBackdrop: true,
        disableClose: false
      });

    ageDialog.componentInstance.onAdd.subscribe(d => {

      this.addDate.setValue(this.memberService.getAddedDate()[0]);

      this.addRetirementDate();
    });



  }
  // Scenario Dialog Open
  openScenarioDialog(): void {

    // set Session Values
    this.defineChartControl();


    // Open Dialog
    const dialogScenario = this.dialog.open(ScenarioDialogComponent,
      {
        width: '45%',
        panelClass: 'scenarioControl',
        data: this.memberService.getChart(),
        hasBackdrop: true,
        disableClose: false
      });

    dialogScenario.componentInstance.onAdd.subscribe(d => {

      this.addedDateArray = this.memberService.getAddedDate();

      this.settingChart(d);

    });
  }

  // Assumption Dialog Open
  openAssumptionDialog(): void {

    this.dialog.open(AssumptionDialogComponent, {
      width: '40%',
      height: '48%',
      panelClass: 'assumptionClass',
      hasBackdrop: true
    });
  }

  // Chart Control Dialog Open
  openDialog(param: string): void {

    this.defineChartControl();


    this.prevRRSPCont = this.memberService.getChart().RRSPCont;
    this.prevAnnualSalary = this.memberService.getChart().AnnualSalaryIncrease;
    this.prevRRSPBalance = this.memberService.getChart().RRSPbalance;
    this.prevRRSPOfReturn = this.memberService.getChart().RateOfReturn;
    this.prevAnnualContribution = this.memberService.getChart().AnnualContribution;
    this.preContributionIncrease = this.memberService.getChart().ContributionIncrease;

    const dialogRef = this.dialog.open(ChartControlDialogComponent,
      {
        width: '58%',
        panelClass: 'chartControl',
        data: this.memberService.getChart(),
        hasBackdrop: true,
        disableClose: false
      });

    this.dialogSub = dialogRef.componentInstance.onAdd.subscribe(d => {


      this.settingChart(d);

      this.isFirstOpen = false;
      // this.isGovernment = this.chartControl.IncludeProjection;
    });

  }

  isControllerChanged(d: Chart) {
    if ((this.memberService.getChart().AnnualSalaryIncrease !== d.AnnualSalaryIncrease) ||
      (this.memberService.getChart().RRSPbalance !== d.RRSPbalance) ||
      (this.memberService.getChart().RateOfReturn !== d.RateOfReturn) ||
      (this.memberService.getChart().ContributionIncrease !== d.ContributionIncrease) ||
      (this.memberService.getChart().AnnualContribution !== d.AnnualContribution) ||
      (this.memberService.getChart().showNet.toString() !== d.showNet.toString()) ||
      (this.memberService.getChart().RRSPCont.toString() !== d.RRSPCont.toString())) {
      // call API'
      return true;
    } else {
      // NOT call API
      return false;
    }


    // if(this.isFirstOpen){
    //   // only at the first time, check with default
    //   return this.checkIsDefaultValue();
    // }else{

    //   if((this.prevAnnualSalary != d.AnnualSalaryIncrease) ||
    //   (this.prevRRSPBalance != d.RRSPbalance) ||
    //   (this.prevRRSPOfReturn != d.RateOfReturn) ||
    //   (this.preContributionIncrease != d.ContributionIncrease) ||
    //   (this.prevAnnualContribution != d.AnnualContribution) ||
    //   (this.prevRRSPCont.toString() != d.RRSPCont.toString())){
    //        //call API'
    //         return true;
    //   }else{
    //         //NOT call API
    //         return false
    //   }

    // }

  }

  settingChart(d: any) {
    //   this.chartControl = d;
    //  this.memberService.setChart(d);

    if (this.chartControl.PaymentOptions === undefined) {
      this.chartControl.PaymentOptions = this.applicableFormsOfPension.filter(f => f.IsNormalForm)[0].FormID;
    }

    if (this.isControllerChanged(d)) {
      this.chartControl = d;
      this.showSpinner = true;
      // Call API
      this.dataManagerSub = this.dataManagerService.getDataFromAPI(false,
        this.chartControl.AnnualSalaryIncrease,
        this.chartControl.RRSPbalance,
        this.chartControl.RateOfReturn,
        this.chartControl.ContributionIncrease,
        this.chartControl.RRSPCont,
        this.chartControl.AnnualContribution).subscribe(e => {

          this.memberService.setMemberInfomation(e);
          this.initialize();
        },
          err => {
            console.log(err);
            this.router.navigate(['error']);
          });


      this.busyConfig.busy = [this.dataManagerSub];   // for spinner

    } else {

      this.initialize(false);
      this.showSpinner = false;
    }
    this.memberService.setChart(d);

    document.getElementById('tblRetirement').style.display = 'none';


  }


  private setDateMinMax(min: string, max: string) {
    const minDate: any = new Date(min);
    const maxDate: any = new Date(max);

    this.dateFilter = (d: any): boolean => {
      const day = d.date();
      return minDate < d && d < maxDate && (day === 1);
    };
  }

  private addTableColumn(name) {


    const cnt = this.monthlyColumns.length - 1;

    if (this.monthlyColumns.filter(d => d === name).length < 1) {
      this.monthlyColumns.splice(cnt, 0, name);  // add coulmn
    }


    // always tax column should be right before total column
    if ((name === TAX_COL) && (this.monthlyColumns.indexOf(TAX_COL) !== this.monthlyColumns.length - 1)) {
      this.removeTableColumn(TAX_COL);
      this.monthlyColumns.splice(this.monthlyColumns.length - 1, 0, TAX_COL);
    }

  }

  private addDetailTableColumn(name) {

    const cnt = this.displayDetailColumns.length - 1;

    if (this.displayDetailColumns.filter(d => d === name).length < 1) {
      this.displayDetailColumns.splice(cnt, 0, name);  // add coulmn
    }

    // always pre65 has to be second position
    if ((name === PRE65_COL) && (this.displayDetailColumns.indexOf(PRE65_COL) !== this.displayDetailColumns.length - 1)) {

      this.removeDetailTableColumn(PRE65_COL);
      this.displayDetailColumns.splice(1, 0, PRE65_COL);
    }

    // always survivor has to be at the end
    if ((name === SURVIVOR_COL) && (this.displayDetailColumns.indexOf(SURVIVOR_COL) !== this.displayDetailColumns.length - 1)) {

      this.removeDetailTableColumn(SURVIVOR_COL);
      this.displayDetailColumns.splice(this.displayDetailColumns.length, 0, SURVIVOR_COL);
    }
  }


  private removeDetailTableColumn(name) {
    if (this.displayDetailColumns.filter(d => d === name).length > 0) {
      const idx = this.displayDetailColumns.indexOf(name);
      this.displayDetailColumns.splice(idx, 1);
    }
  }


  private removeTableColumn(name) {
    if (this.monthlyColumns.filter(d => d === name).length > 0) {
      const idx = this.monthlyColumns.indexOf(name);
      this.monthlyColumns.splice(idx, 1);
    }
  }


  private setMonthlyColumn(): void {
    // initialize order of column
    this.monthlyColumns = [RETDATE_COL, RETAGE_COL, EMP_COL, RPP_COL, TOTAL_COL];

    if (this.memberData.IsYoungerThan65) {
      this.addTableColumn(BRIDGE_COL);
    } else {
      this.removeTableColumn(BRIDGE_COL);
    }

    if (this.memberData.IsSERPEligible) {
      this.addTableColumn(SERP_S_COL);
    } else {
      this.removeTableColumn(SERP_S_COL);
    }

    if (this.memberData.HasRRSPPension) {
      this.addTableColumn(RRSP_S_COL);
    } else {
      this.removeTableColumn(RRSP_S_COL);
    }


    if (this.chartControl.IncludeProjection) {
      this.addTableColumn(CPP_S_COL);
      this.addTableColumn(OAS_S_COL);
    } else {
      this.removeTableColumn(CPP_S_COL);
      this.removeTableColumn(OAS_S_COL);
    }

    if (this.chartControl.showNet) {
      this.addTableColumn(TAX_COL);
    } else {
      this.removeTableColumn(TAX_COL);
    }

    this.colspanM = this.monthlyColumns.length - 1;

  }


  // Form of Pension Table column setting
  private setDetailTblColumn(): void {

    if (this.icludesPre65) {
      this.addDetailTableColumn(PRE65_COL);
    } else {
      this.removeDetailTableColumn(PRE65_COL);
    }


    if (this.memberData.HasSpouse && !this.chartControl.showNet) {
      this.addDetailTableColumn(SURVIVOR_COL);
    } else {
      this.removeDetailTableColumn(SURVIVOR_COL);
    }

    this.colspanDetail = this.displayDetailColumns.length - 1;
  }



  private createBarChart(isSERP: boolean, hasSpouse: boolean, hasRRSP: boolean, IsYoungerThan65: boolean): void {

    const element = this.chartContainer.nativeElement;
    const margin = { top: 10, right: 10, bottom: 50, left: 90 };
    const data: RetirementEvents[] = this.monthlyPension;
    const offsetWidth = 910;
    const offsetHeight = 320;


    d3.select('.bChart').remove();
    // const svg = d3.select(element).append('svg')
    //               .attr('class', 'bChart')
    //               .attr('width', offsetWidth)
    //               .attr('height', "460px");

    const svg = d3.select(element).append('svg')
      .attr('preserveAspectRatio', 'xMinYMin meet')
      .attr('viewBox', '0 0 910 400')
      .attr('width', '100%')
      .attr('height', '400px')
      .classed('bChart', true);

    const contentWidth = offsetWidth - margin.left - margin.right;
    const contentHeight = offsetHeight - margin.top - margin.bottom;

    // max of post-65
    // var maxData = Math.max.apply(null, data.map(function (d) { return d.post65; }));/

    const g = svg.append('g').attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

    const x = this.setXaxis(g, data, contentWidth, contentHeight);
    const y = this.setYaxis(g, data, contentWidth, contentHeight);

    this.setRectPre(g, data, x, y, contentHeight, isSERP, hasSpouse, hasRRSP, IsYoungerThan65);
    this.setRectPost(g, data, x, y, contentHeight, isSERP, hasSpouse, hasRRSP);

    this.setBarPreLabel(g, data, x, y, hasSpouse);
    this.setBarPostLabel(g, data, x, y, hasSpouse);


    if ((!this.chartControl.showNet) && (hasSpouse)) {
      this.setSurvivorLabel(g, data, x, y, hasSpouse);
      this.setRectSurviovr(g, data, x, y, contentHeight, isSERP, hasRRSP);
    }

    // Bottom Label Setting
    this.setChartBottomLabel(g, data, isSERP, hasRRSP, IsYoungerThan65);

  }


  private setStartPositionX(x: any, retirementDate: any, hasSpouse: boolean, isPre65: boolean): any {
    const adjustCnt = 5;
    if (hasSpouse) {
      if (isPre65) {
        return x(retirementDate) - x.bandwidth() / 3.5;
      } else {
        return x(retirementDate) + x.bandwidth() / 4;
      }
    } else {
      if (isPre65) {
        return x(retirementDate) - x.bandwidth() / 12;
      } else {
        return x(retirementDate) + x.bandwidth() * 3 / 5;
      }
    }

  }

  private setStartNormalPositionX(x: any, retirementDate: any, hasSpouse: boolean): any {
    if (hasSpouse) {
      return x(retirementDate) - x.bandwidth() / 12;
    } else {
      return x(retirementDate) + x.bandwidth() / 4;
    }
  }

  private setStartSurvivor(x: any, retirementDate: any, IncludesPre65: boolean): any {
    if (IncludesPre65) {
      return x(retirementDate) + (x.bandwidth() - x.bandwidth() / 4.5);
    } else {
      return x(retirementDate) + (x.bandwidth() - x.bandwidth() / 1.85);
    }
  }

  private setSurvivorLabel(g: any, data: RetirementEvents[], x: any, y: any, hasSpouse: boolean): void {
    const fontSize = '10px';
    // Survivor Amount Label
    g.selectAll('.barlblSur')
      .data(data)
      .enter()
      .append('text')
      .attr('class', 'barlblSur')
      .attr('x', d => {
        return this.setStartSurvivor(x, d.RetirementDate, d.IncludesPre65);
        // if(d.IncludesPre65){
        //   return x(d.RetirementDate)+ (x.bandwidth()/1.85);
        // }else{
        //   return x(d.RetirementDate)+ (x.bandwidth() -8);
        // }
      })
      .attr('y', d => y(d.RetirementBenefits.filter(k => k.FormOfPension === this.chartControl.PaymentOptions)[0].TotalSurvivor) - 40)
      .text(d =>
        d3.format('$,.0f')(d.RetirementBenefits.filter(i => i.FormOfPension === this.chartControl.PaymentOptions)[0].TotalSurvivor))
      .attr('font-size', fontSize).attr('font-weight', 'bold');

    // (Survivor) Lael
    g.selectAll('.barlblSur2')
      .data(data)
      .enter()
      .append('text')
      .attr('class', 'barlblSur2')
      .attr('x', d => {
        return this.setStartSurvivor(x, d.RetirementDate, d.IncludesPre65);
        // if(d.IncludesPre65 ){
        //     return x(d.RetirementDate)+ (x.bandwidth()/1.85)-5;
        // }else{
        //     return x(d.RetirementDate)+ (x.bandwidth() -18);
        // }
      })
      .attr('y', d => y(d.RetirementBenefits.filter(p => p.FormOfPension === this.chartControl.PaymentOptions)[0].TotalSurvivor) - 25)
      .text(this.RECT.CHART_COL7).attr('font-size', fontSize);

    g.selectAll('.barlblSur3')
      .data(data)
      .enter()
      .append('text')
      .attr('class', 'barlblSur3')
      .attr('x', d => {
        return this.setStartSurvivor(x, d.RetirementDate, d.IncludesPre65);
        // if(d.IncludesPre65 ){
        //     return x(d.RetirementDate)+ (x.bandwidth()/1.85)-5;
        // }else{
        //     return x(d.RetirementDate)+ (x.bandwidth() -18);
        // }
      })
      .attr('y', d => y(d.RetirementBenefits.filter(c => c.FormOfPension === this.chartControl.PaymentOptions)[0].TotalSurvivor) - 10)
      .text(this.RECT.CHART_COL8).attr('font-size', fontSize);
  }

  private setRectSurviovr(g: any, data: RetirementEvents[], x: any, y: any, contentHeight: number, isSERP: boolean, hasRRSP: boolean) {

    // RPP rect
    const rpp = g.selectAll('.barS')
      .data(data)
      .enter().append('rect')
      .attr('class', 'barS')
      .attr('x', d => this.setStartSurvivor(x, d.RetirementDate, d.IncludesPre65))
      .attr('y', d => y(d.RetirementBenefits.filter(r => r.FormOfPension === this.chartControl.PaymentOptions)[0].RPPSurvivorBenefit))
      .attr('width', x.bandwidth() / 2)
      .attr('height', d => contentHeight -
        y(d.RetirementBenefits.filter(t => t.FormOfPension === this.chartControl.PaymentOptions)[0].RPPSurvivorBenefit))
      .attr('fill', COLOR_RPP);
    rpp.on('click', e => this.moveRect(g, x, e));

    // SERP
    if (isSERP) {
      const serp = g.selectAll('.stakeSS')
        .data(data)
        .enter().append('rect')
        .attr('class', 'stakeSS')
        .attr('x', d => this.setStartSurvivor(x, d.RetirementDate, d.IncludesPre65))
        .attr('y', d => {
          return y(d.RetirementBenefits.filter(q => q.FormOfPension === this.chartControl.PaymentOptions)[0].RPPSurvivorBenefit)
            - (contentHeight -
              y(d.RetirementBenefits.filter(s => s.FormOfPension === this.chartControl.PaymentOptions)[0].SERPSurvivorBenefit));
        })
        .attr('width', x.bandwidth() / 2)
        .attr('height', d => (contentHeight
          - y(d.RetirementBenefits.filter(s => s.FormOfPension === this.chartControl.PaymentOptions)[0].SERPSurvivorBenefit)))
        .attr('fill', COLOR_SERP);
      serp.on('click', e => this.moveRect(g, x, e));
    }

    if (hasRRSP) {

      const rrsp = g.selectAll('.stakeSRRSP')
        .data(data)
        .enter().append('rect')
        .attr('class', 'stakeSRRSP')
        .attr('x', d => this.setStartSurvivor(x, d.RetirementDate, d.IncludesPre65))
        .attr('y', d => {
          return y(d.RetirementBenefits.filter(f => f.FormOfPension === this.chartControl.PaymentOptions)[0].RPPSurvivorBenefit)
            - (isSERP ? (contentHeight -
              y(d.RetirementBenefits.filter(t => t.FormOfPension === this.chartControl.PaymentOptions)[0].SERPSurvivorBenefit)) : 0)
            - (contentHeight - y(d.RetirementBenefits.filter(h => h.FormOfPension === this.chartControl.PaymentOptions)[0].RRSPSurvivor));
        })
        .attr('width', x.bandwidth() / 2)
        .attr('height', d => (contentHeight
          - y(d.RetirementBenefits.filter(e => e.FormOfPension === this.chartControl.PaymentOptions)[0].RRSPSurvivor)))
        .attr('fill', COLOR_RRSP);
      rrsp.on('click', e => this.moveRect(g, x, e));


    }

  }


  private getHeightPostNet(y: any, d: any): number {
    return this.chartControl.IncludeProjection ?
      y(d.RetirementBenefits.filter(g => g.FormOfPension === this.chartControl.PaymentOptions)[0].TotalPRGPost65Net) :
      y(d.RetirementBenefits.filter(g => g.FormOfPension === this.chartControl.PaymentOptions)[0].TotalPRPost65Net);
  }

  private getHeightPostTax(y: any, d: any): number {
    return this.chartControl.IncludeProjection ?
      y(d.RetirementBenefits.filter(g => g.FormOfPension === this.chartControl.PaymentOptions)[0].TaxPRGPost65) :
      y(d.RetirementBenefits.filter(g => g.FormOfPension === this.chartControl.PaymentOptions)[0].TaxPRPost65);
  }

  private getHeightPreTax(y: any, d: any): number {
    return this.chartControl.IncludeProjection ?
      y(d.RetirementBenefits.filter(g => g.FormOfPension === this.chartControl.PaymentOptions)[0].TaxPRGPre65) :
      y(d.RetirementBenefits.filter(g => g.FormOfPension === this.chartControl.PaymentOptions)[0].TaxPRPre65);
  }



  private setRectPost(g: any, data: RetirementEvents[],
    x: any, y: any, contentHeight: number, isSERP: boolean, hasSpouse: boolean, hasRRSP: boolean) {


    if (this.chartControl.showNet) {

      hasSpouse = false;
      const post65SN = g.selectAll('bar2PostShowNet')
        .data(data)
        .enter().append('rect')
        .attr('class', 'bar2PostShowNet')
        .attr('x', d => {
          if (d.IncludesPre65) {
            return this.setStartPositionX(x, d.RetirementDate, hasSpouse, false);
          } else {
            return this.setStartNormalPositionX(x, d.RetirementDate, hasSpouse);
          }
        })
        .attr('y', d => this.getHeightPostNet(y, d))
        .attr('width', x.bandwidth() / 2)
        .attr('height', d => contentHeight - (this.getHeightPostNet(y, d)))
        .attr('fill', COLOR_TOTAL);

      post65SN.on('click', e => {
        this.moveRect(g, x, e);
      });

      const taxPost = g.selectAll('.stakeSPostTax')
        .data(data)
        .enter().append('rect')
        .attr('class', 'stakeSPostTax')
        .attr('x', d => {
          if (d.IncludesPre65) {
            return this.setStartPositionX(x, d.RetirementDate, hasSpouse, false);
          } else {
            return this.setStartNormalPositionX(x, d.RetirementDate, hasSpouse);
          }
        })
        .attr('y', d => {
          return (this.getHeightPostNet(y, d))
            - (contentHeight - this.getHeightPostTax(y, d));
        })
        .attr('width', x.bandwidth() / 2)
        .attr('height', d => contentHeight - this.getHeightPostTax(y, d))
        .attr('fill', COLOR_TAX);
      taxPost.on('click', e => this.moveRect(g, x, e));

      return;

    }

    // RPP rect
    const post65 = g.selectAll('bar2Post')
      .data(data)
      .enter().append('rect')
      .attr('class', 'bar2Post')
      .attr('x', d => {
        if (d.IncludesPre65) {
          return this.setStartPositionX(x, d.RetirementDate, hasSpouse, false);
        } else {
          return this.setStartNormalPositionX(x, d.RetirementDate, hasSpouse);
        }
      })
      .attr('y', d => y(d.RetirementBenefits.filter(b => b.FormOfPension === this.chartControl.PaymentOptions)[0].RPPBenefitPost65))
      .attr('width', x.bandwidth() / 2)
      .attr('height', d => {
        const yValue = y(d.RetirementBenefits.filter(r => r.FormOfPension === this.chartControl.PaymentOptions)[0].RPPBenefitPost65);
        const ret = contentHeight - yValue;
        return ret < 0 ? 0 : ret;
      })
      .attr('fill', COLOR_RPP);


    post65.on('click', e => {
      this.moveRect(g, x, e);
    });

    // SERP
    if (isSERP) {
      const serp = g.selectAll('.stakeSPost')
        .data(data)
        .enter().append('rect')
        .attr('class', 'stakeSPost')
        .attr('x', d => {
          if (d.IncludesPre65) {
            return this.setStartPositionX(x, d.RetirementDate, hasSpouse, false);
          } else {
            return this.setStartNormalPositionX(x, d.RetirementDate, hasSpouse);
          }
        })
        .attr('y', d => {
          return y(d.RetirementBenefits.filter(j => j.FormOfPension === this.chartControl.PaymentOptions)[0].RPPBenefitPost65)
            - (contentHeight - y(d.RetirementBenefits.filter(z => z.FormOfPension === this.chartControl.PaymentOptions)[0].SERPBenefit));
        })
        .attr('width', x.bandwidth() / 2)
        .attr('height', d => (contentHeight
          - y(d.RetirementBenefits.filter(p => p.FormOfPension === this.chartControl.PaymentOptions)[0].SERPBenefit)))
        .attr('fill', COLOR_SERP);
      serp.on('click', e => this.moveRect(g, x, e));
    }


    // CPP/OAS ON
    if (this.chartControl.IncludeProjection) {

      // CPP
      // .data(data.filter(d =>(d.RetirementDateType != RET_TYPE.NORMAL) || (d.RetirementDateType != RET_TYPE.MAXI)))
      const cpp = g.selectAll('.stakeC')
        .data(data)
        .enter().append('rect')
        .attr('class', 'stakeC')
        .attr('x', d => {

          if (d.IncludesPre65) {
            return this.setStartPositionX(x, d.RetirementDate, hasSpouse, false);
          } else {
            return this.setStartNormalPositionX(x, d.RetirementDate, hasSpouse);
          }

        })
        .attr('y', d => {
          return y(d.RetirementBenefits.filter(t => t.FormOfPension === this.chartControl.PaymentOptions)[0].RPPBenefitPost65)
            - (isSERP ?
              (contentHeight -
                y(d.RetirementBenefits.filter(j => j.FormOfPension === this.chartControl.PaymentOptions)[0].SERPBenefit)) : 0)
            - (contentHeight - y(d.RetirementBenefits.filter(v => v.FormOfPension === this.chartControl.PaymentOptions)[0].CPPAt65));
        })
        .attr('width', x.bandwidth() / 2)
        .attr('height', d => (contentHeight
          - y(d.RetirementBenefits.filter(t => t.FormOfPension === this.chartControl.PaymentOptions)[0].CPPAt65)))
        .attr('fill', COLOR_CPP);
      cpp.on('click', e => this.moveRect(g, x, e));

      // OAS
      const oas = g.selectAll('.stakeO')
        .data(data)
        .enter().append('rect')
        .attr('class', 'stakeO')
        .attr('x', d => {
          if (d.IncludesPre65) {
            return this.setStartPositionX(x, d.RetirementDate, hasSpouse, false);
          } else {
            return this.setStartNormalPositionX(x, d.RetirementDate, hasSpouse);
          }
        })
        .attr('y', d => {
          return y(d.RetirementBenefits.filter(f => f.FormOfPension === this.chartControl.PaymentOptions)[0].RPPBenefitPost65)
            - (isSERP ?
              (contentHeight -
                y(d.RetirementBenefits.filter(w => w.FormOfPension === this.chartControl.PaymentOptions)[0].SERPBenefit)) : 0)
            - (contentHeight - y(d.RetirementBenefits.filter(e => e.FormOfPension === this.chartControl.PaymentOptions)[0].CPPAt65))
            - (contentHeight - y(d.RetirementBenefits.filter(f => f.FormOfPension === this.chartControl.PaymentOptions)[0].OASAt65));
        })
        .attr('width', x.bandwidth() / 2)
        .attr('height', d => (contentHeight
          - y(d.RetirementBenefits.filter(h => h.FormOfPension === this.chartControl.PaymentOptions)[0].OASAt65)))
        .attr('fill', COLOR_OAS);
      oas.on('click', e => this.moveRect(g, x, e));
    }


    if (hasRRSP) {

      // RRSP
      const rrsp = g.selectAll('.stakeRRSPPost')
        .data(data.filter(d => d.IncludesPre65))
        .enter().append('rect')
        .attr('class', 'stakeRRSPPost')
        .attr('x', d => {
          if (d.IncludesPre65) {
            return this.setStartPositionX(x, d.RetirementDate, hasSpouse, false);
          } else {
            return this.setStartNormalPositionX(x, d.RetirementDate, hasSpouse);
          }
        })
        .attr('y', d => {
          return y(d.RetirementBenefits.filter(q => q.FormOfPension === this.chartControl.PaymentOptions)[0].RPPBenefitPost65)
            - (isSERP ?
              (contentHeight -
                y(d.RetirementBenefits.filter(t => t.FormOfPension === this.chartControl.PaymentOptions)[0].SERPBenefit)) : 0)
            - (this.chartControl.IncludeProjection ?
              ((contentHeight - y(d.RetirementBenefits.filter(k => k.FormOfPension === this.chartControl.PaymentOptions)[0].CPPAt65))
                + (contentHeight -
                  y(d.RetirementBenefits.filter(j => j.FormOfPension === this.chartControl.PaymentOptions)[0].OASAt65))) : 0)
            - (contentHeight - y(d.RetirementBenefits.filter(p => p.FormOfPension === this.chartControl.PaymentOptions)[0].RRSPPension));
        })
        .attr('width', x.bandwidth() / 2)
        .attr('height', d => (contentHeight
          - y(d.RetirementBenefits.filter(q => q.FormOfPension === this.chartControl.PaymentOptions)[0].RRSPPension)))
        .attr('fill', COLOR_RRSP);
      rrsp.on('click', e => this.moveRect(g, x, e));

    }

  }


  private getHeightPreNet(y: any, d: any): number {
    return this.chartControl.IncludeProjection ?
      y(d.RetirementBenefits.filter(g => g.FormOfPension === this.chartControl.PaymentOptions)[0].TotalPRGPre65Net) :
      y(d.RetirementBenefits.filter(g => g.FormOfPension === this.chartControl.PaymentOptions)[0].TotalPRPre65Net);
  }



  private setRectPre(g: any, data: RetirementEvents[],
    x: any, y: any, contentHeight: number, isSERP: boolean, hasSpouse: boolean, hasRRSP: boolean, IsYoungerThan65: boolean) {

    if (this.chartControl.showNet) {

      hasSpouse = false;

      const total = g.selectAll('.barShowNet')
        .data(data.filter(d => d.IncludesPre65))
        .enter().append('rect')
        .attr('class', 'barShowNet')
        .attr('x', d => this.setStartPositionX(x, d.RetirementDate, hasSpouse, true))
        .attr('y', d => this.getHeightPreNet(y, d))
        .attr('width', x.bandwidth() / 2)
        .attr('height', d => contentHeight - (this.getHeightPreNet(y, d)))
        .attr('fill', COLOR_TOTAL);
      total.on('click', e => this.moveRect(g, x, e));

      const tax = g.selectAll('.barShowNetTax')
        .data(data.filter(d => d.IncludesPre65))
        .enter().append('rect')
        .attr('class', 'barShowNetTax')
        .attr('x', d => this.setStartPositionX(x, d.RetirementDate, hasSpouse, true))
        .attr('y', d =>
          (this.getHeightPreNet(y, d))
          - (contentHeight - this.getHeightPreTax(y, d))     // TODO -> tax
        )
        .attr('width', x.bandwidth() / 2)
        .attr('height', d => contentHeight - (this.getHeightPreTax(y, d)))
        .attr('fill', COLOR_TAX);
      tax.on('click', e => this.moveRect(g, x, e));

      return;

    }


    // ------(pre-65)--------
    // RPP rect
    const rpp = g.selectAll('.bar')
      .data(data.filter(d => d.IncludesPre65))
      .enter().append('rect')
      .attr('class', 'bar')
      .attr('x', d => this.setStartPositionX(x, d.RetirementDate, hasSpouse, true))
      .attr('y', d => y(d.RetirementBenefits.filter(p => p.FormOfPension === this.chartControl.PaymentOptions)[0].RPPBenefitPre65))
      .attr('width', x.bandwidth() / 2)
      .attr('height', d => contentHeight -
        y(d.RetirementBenefits.filter(z => z.FormOfPension === this.chartControl.PaymentOptions)[0].RPPBenefitPre65))
      .attr('fill', COLOR_RPP);

    rpp.on('click', e => this.moveRect(g, x, e));


    // Bridge rect
    if (IsYoungerThan65) {

      const bridge = g.selectAll('.stake')
        .data(data.filter(d => d.IncludesPre65))
        .enter().append('rect')
        .attr('class', 'stake')
        .attr('x', d => this.setStartPositionX(x, d.RetirementDate, hasSpouse, true))
        .attr('y', d => {
          return y(d.RetirementBenefits.filter(r => r.FormOfPension === this.chartControl.PaymentOptions)[0].RPPBenefitPre65)
            - (contentHeight - y(d.RetirementBenefits.filter(e => e.FormOfPension === this.chartControl.PaymentOptions)[0].Bridge));
        })
        .attr('width', x.bandwidth() / 2)
        .attr('height', d => (contentHeight
          - y(d.RetirementBenefits.filter(w => w.FormOfPension === this.chartControl.PaymentOptions)[0].Bridge)))
        .attr('fill', COLOR_BRIDGE);

      bridge.on('click', e => this.moveRect(g, x, e));
    }

    // SERP
    if (isSERP) {
      const serp = g.selectAll('.stakeS')
        .data(data.filter(d => d.IncludesPre65))
        .enter().append('rect')
        .attr('class', 'stakeS')
        .attr('x', d => this.setStartPositionX(x, d.RetirementDate, hasSpouse, true))
        .attr('y', d => {
          return y(d.RetirementBenefits.filter(h => h.FormOfPension === this.chartControl.PaymentOptions)[0].RPPBenefitPre65)
            - (IsYoungerThan65 ?
              (contentHeight - y(d.RetirementBenefits.filter(i => i.FormOfPension === this.chartControl.PaymentOptions)[0].Bridge))
              : 0)
            - (contentHeight - y(d.RetirementBenefits.filter(f => f.FormOfPension === this.chartControl.PaymentOptions)[0].SERPBenefit));
        })
        .attr('width', x.bandwidth() / 2)
        .attr('height', d => (contentHeight
          - y(d.RetirementBenefits.filter(u => u.FormOfPension === this.chartControl.PaymentOptions)[0].SERPBenefit)))
        .attr('fill', COLOR_SERP);
      serp.on('click', e => this.moveRect(g, x, e));
    }

    // RRSP
    if (hasRRSP) {
      const rrsp = g.selectAll('.stakeRRSP')
        .data(data.filter(d => d.IncludesPre65))
        .enter().append('rect')
        .attr('class', 'stakeRRSP')
        .attr('x', d => this.setStartPositionX(x, d.RetirementDate, hasSpouse, true))
        .attr('y', d => {
          return y(d.RetirementBenefits.filter(f => f.FormOfPension === this.chartControl.PaymentOptions)[0].RPPBenefitPre65)
            - (IsYoungerThan65 ?
              (contentHeight - y(d.RetirementBenefits.filter(i => i.FormOfPension === this.chartControl.PaymentOptions)[0].Bridge)) : 0)
            - (isSERP ?
              (contentHeight - y(d.RetirementBenefits.filter(j => j.FormOfPension === this.chartControl.PaymentOptions)[0].SERPBenefit))
              : 0)
            - (contentHeight - y(d.RetirementBenefits.filter(k => k.FormOfPension === this.chartControl.PaymentOptions)[0].RRSPPension));
        })
        .attr('width', x.bandwidth() / 2)
        .attr('height', d => (contentHeight
          - y(d.RetirementBenefits.filter(h => h.FormOfPension === this.chartControl.PaymentOptions)[0].RRSPPension)))
        .attr('fill', COLOR_RRSP);

      rrsp.on('click', e => this.moveRect(g, x, e));

    }

  }

  private moveRect(g: any, x: any, e: any): void {

    document.getElementById('tblRetirement').style.display = 'table';
    document.getElementById('btnBottom').style.display = 'block';

    g.select('.midRect')
      .attr('x', d => x(e.RetirementDate) - (x.bandwidth() / 2.5))
      .attr('y', 0)
      .attr('opacity', 1);

    g.select('.midTxt')
      .attr('x', d => x(e.RetirementDate) - (x.bandwidth() / 2.5) + 5)
      .attr('y', 25)
      .attr('opacity', 1);

    g.select('.midTxt2')
      .attr('x', d => x(e.RetirementDate) - (x.bandwidth() / 2.5) + 5)
      .attr('y', 45)
      .attr('opacity', 1);

    g.select('.midTxt3')
      .attr('x', d => x(e.RetirementDate) - (x.bandwidth() / 2.5) + 5)
      .attr('y', 65)
      .attr('opacity', 1);

    this.retirementBenefits = e.RetirementBenefits;  // set table data
    this.icludesPre65 = e.IncludesPre65;              // set flg
    this.setDetailTblColumn();
    this.dateAssuming = e.RetirementDate;   // Assuming Retirement At

  }



  private setBarPreLabel(g: any, data: RetirementEvents[], x: any, y: any, hasSpouse: boolean): void {

    const fontSize = '10px';
    const barCnt = data.length;

    if (this.chartControl.showNet) {

      hasSpouse = false;      // when showNet, no spouse column in bar chart

      // Net
      g.selectAll('.barlblPre3')
        .data(data.filter(d => d.IncludesPre65))
        .enter()
        .append('text')
        .attr('class', 'barlblPre3')
        .attr('x', d => {
          return this.setStartPositionX(x, d.RetirementDate, hasSpouse, true) - 3;
        })
        .attr('y', d => y(this.getPre65Gross(d)) - 30)                                       // display Gross
        .text(d => d3.format('$,.0f')(this.getPre65(d, true))).attr('font-size', fontSize)
        .attr('font-weight', 'bold');      // display Net


      // (Pre-65) Lael
      g.selectAll('.barlblPre4')
        .data(data.filter(d => d.IncludesPre65))
        .enter()
        .append('text')
        .attr('class', 'barlblPre4')
        .attr('x', d => {
          return this.setStartPositionX(x, d.RetirementDate, hasSpouse, true);
        })
        .attr('y', d => y(this.getPre65Gross(d)) - 10)
        .text(this.RECT.CHART_COL1).attr('font-size', fontSize);

    } else {

      // Pre-65 Amount Label
      g.selectAll('.barlblPre')
        .data(data.filter(d => d.IncludesPre65))
        .enter()
        .append('text')
        .attr('class', 'barlblPre')
        .attr('x', d => {
          return this.setStartPositionX(x, d.RetirementDate, hasSpouse, true) + 2;
        })
        .attr('y', d => y(this.getPre65(d, true)) - 30)
        .text(d => d3.format('$,.0f')(this.getPre65(d, true))).attr('font-size', fontSize).attr('font-weight', 'bold');

      // (Pre-65) Lael
      g.selectAll('.barlblPre2')
        .data(data.filter(d => d.IncludesPre65))
        .enter()
        .append('text')
        .attr('class', 'barlblPre2')
        .attr('x', d => {
          return this.setStartPositionX(x, d.RetirementDate, hasSpouse, true) + 3;
        })
        .attr('y', d => y(this.getPre65(d, true)) - 10)
        .text(this.RECT.CHART_COL1).attr('font-size', fontSize);


    }

  }

  private setBarPostLabel(g: any, data: RetirementEvents[], x: any, y: any, hasSpouse: boolean): void {
    const fontSize = '10px';
    const barCnt = data.length;

    if (this.chartControl.showNet) {
      hasSpouse = false;      // when showNet, no spouse column in bar chart (even if they have spouse)

      // Post-65 Amount Label
      g.selectAll('.barlblS')
        .data(data)
        .enter()
        .append('text')
        .attr('class', 'barlblS')
        .attr('x', d => {
          if (d.IncludesPre65) {
            return this.setStartPositionX(x, d.RetirementDate, hasSpouse, false);
          } else {
            return this.setStartNormalPositionX(x, d.RetirementDate, hasSpouse);
          }
        })
        .attr('y', d => y(this.getPost65Gross(d)) - 30)            // position Gross (Net + Tax)
        .text(d => d3.format('$,.0f')(this.getPost65(d, true)))   // display  Net
        .attr('font-size', fontSize)
        .attr('font-weight', 'bold');

      // (Post-65) Lael
      g.selectAll('.barlblSA')
        .data(data)
        .enter()
        .append('text')
        .attr('class', 'barlblSA')
        .attr('x', d => {
          if (d.IncludesPre65) {
            return this.setStartPositionX(x, d.RetirementDate, hasSpouse, false);
          } else {
            return this.setStartNormalPositionX(x, d.RetirementDate, hasSpouse);
          }
        })
        .attr('y', d => y(this.getPost65Gross(d)) - 10)     // position Gross (Net + Tax)
        .text(this.RECT.CHART_COL2)
        .attr('font-size', fontSize);

    } else {
      // Post-65 Amount Label
      g.selectAll('.barlbl')
        .data(data)
        .enter()
        .append('text')
        .attr('class', 'barlbl')
        .attr('x', d => {
          if (d.IncludesPre65) {
            return this.setStartPositionX(x, d.RetirementDate, hasSpouse, false);
          } else {
            return this.setStartNormalPositionX(x, d.RetirementDate, hasSpouse);
          }
        })
        .attr('y', d => y(this.getPost65(d, true)) - 30)
        .text(d => d3.format('$,.0f')(this.getPost65(d, true)))
        .attr('font-size', fontSize)
        .attr('font-weight', 'bold');
      // (Post-65) Lael
      g.selectAll('.barlbl1')
        .data(data)
        .enter()
        .append('text')
        .attr('class', 'barlbl1')
        .attr('x', d => {
          if (d.IncludesPre65) {
            return this.setStartPositionX(x, d.RetirementDate, hasSpouse, false);
          } else {
            return this.setStartNormalPositionX(x, d.RetirementDate, hasSpouse);
          }
        })
        .attr('y', d => y(this.getPost65(d, true)) - 10)
        .text(this.RECT.CHART_COL2)
        .attr('font-size', fontSize);
    }

  }

  // Y axis Setting
  private setYaxis(g: any, data: RetirementEvents[], contentWidth: number, contentHeight: number): any {
    const fontSize = '12px';
    // domain : Yaxis range
    // showNet == true : gross amount is used
    // showNet == false : total amount is used accodingly
    const y = d3
      .scaleLinear()
      .domain([0, d3.max(data, d => {

        const ret = d3.max(d.RetirementBenefits.filter(f => f.FormOfPension === this.chartControl.PaymentOptions), e => {
          return this.chartControl.showNet ? this.getPre65Gross(d) : this.getPre65(e);
          // if((d.RetirementDateType == RET_TYPE.NORMAL) || (d.RetirementDateType == RET_TYPE.MAXI)){
          //   return 0
          // }else{
          //   return this.chartControl.showNet ? this.getPre65Gross(d) : this.getPre65(e)
          // }
        }) + d3.max(d.RetirementBenefits.filter(f => f.FormOfPension === this.chartControl.PaymentOptions), e => {
          return this.chartControl.showNet ? this.getPre65Gross(d) : this.getPre65(e);
        });

        return ret + (ret / 5);

      })

      ])  // y axis heights   (d3.max(data, d => d.post65))
      .rangeRound([contentHeight, 0]);


    // Y axis
    g.append('g')
      .attr('class', 'axis axis--y')
      .style('font-size', fontSize)
      .call(d3.axisLeft(y).ticks(5).tickSize(-6).tickFormat(d => d3.format('$,.2f')(d)));

    return y;
  }


  // Grey Rectangle Setting
  private setMiddleRect(g: any, data: RetirementEvents[], x: any, contentHeight: number) {

    const barCnt = data.length;
    let msg1 = '';
    let msg2 = '';
    let msg3 = '';
    if (barCnt === 3) {
      msg1 = this.RECT.CHART_COL3 + this.RECT.CHART_COL4;
      msg2 = this.RECT.CHART_COL5 + this.RECT.CHART_COL6;
    } else if (barCnt === 1) {
      msg1 = this.RECT.CHART_COL3 + this.RECT.CHART_COL4 + this.RECT.CHART_COL5 + this.RECT.CHART_COL6;
    } else {
      msg1 = this.RECT.CHART_COL3;
      msg2 = this.RECT.CHART_COL4 + this.RECT.CHART_COL5;
      msg3 = this.RECT.CHART_COL6;
    }

    // .data(data.filter(d => d.RetirementDateType == 0))
    g.selectAll('midRect')
      .data(data)
      .enter().append('rect')
      .attr('class', 'midRect')
      .attr('x', d => x(d.RetirementDate) - (x.bandwidth() / 2.5))
      .attr('y', 0)
      .attr('width', d => {
        return x.bandwidth() + ((x.bandwidth() / 2.4) * 2) + 12;
      })
      .attr('height', contentHeight + 75)
      .attr('fill', '#E8E8E8')
      .attr('opacity', 0);

    // First row
    //  .data(data.filter(d => d.RetirementDateType == 0))
    g.selectAll('midTxt')
      .data(data)
      .enter().append('text')
      .attr('class', 'midTxt')
      .attr('x', d => x(d.RetirementDate) - (x.bandwidth() / 4.5))
      .attr('y', 25)
      .text(msg1)
      .attr('font-size', '14px')
      .attr('opacity', 0);

    // Second row
    // .data(data.filter(d => d.RetirementDateType == 0))
    if (barCnt !== 1) {
      g.selectAll('midTxt2')
        .data(data)
        .enter().append('text')
        .attr('class', 'midTxt2')
        .attr('x', d => x(d.RetirementDate) - (x.bandwidth() / 4.5))
        .attr('y', 45)
        .text(msg2)
        .attr('font-size', '14px')
        .attr('opacity', 0);

      if (barCnt !== 3) {
        // Third row
        //      .data(data.filter(d => d.RetirementDateType == 0))
        g.selectAll('midTxt3')
          .data(data)
          .enter().append('text')
          .attr('class', 'midTxt3')
          .attr('x', d => x(d.RetirementDate) - (x.bandwidth() / 4.5))
          .attr('y', 65)
          .text(msg3)
          .attr('font-size', '14px')
          .attr('opacity', 0);
      }
    }


  }

  // X axis setting
  private setXaxis(g: any, data: RetirementEvents[], contentWidth: number, contentHeight: number): any {
    const fontSize = '13px';
    const x = d3
      .scaleBand()
      .rangeRound([0, contentWidth])
      .padding(0.5)
      .domain(data.map(d => d.RetirementDate));

    const x2 = d3
      .scaleBand()
      .rangeRound([0, contentWidth])
      .padding(0.5)
      .domain(data.map(d => d.RetirementDate));

    // Middle Grey Rectangle
    this.setMiddleRect(g, data, x, contentHeight);

    // X axis
    g.append('g')
      .attr('class', 'axis')
      .style('font-size', fontSize)
      .attr('transform', 'translate(0,' + contentHeight + ')')
      .call(d3.axisBottom(x).tickSize(0).tickFormat((d, i) => data[i].RetirementDateType.toString()
        .replace('0', this.RET_TYPE_NAME.REDUCED)
        .replace('1', this.RET_TYPE_NAME.UNREDUCED)
        .replace('2', this.RET_TYPE_NAME.NORMAL)
        .replace('3', this.RET_TYPE_NAME.MAXI)
        .replace('4', this.RET_TYPE_NAME.OTHER)))
      .selectAll('.tick text')
      .call(this.wrap, x.bandwidth());

    // X axis for second line label
    g.append('g')
      .attr('class', 'axis axis--x')
      .style('font-size', fontSize)
      .attr('transform', 'translate(0,' + (contentHeight + 60) + ')')               // Position of Label (adjust number for up and down)
      .call(d3.axisBottom(x2).tickSize(0).tickFormat(d => moment(d).format('ll')))  // Format : YYY mm dd
      .selectAll('path').attr('stroke-width', '0px');
    return x;
  }

  // Chart coloumn detail setting
  private setChartBottomLabel(g: any, data: RetirementEvents[], isSERP: boolean, hasRRSP: boolean, IsYoungerThan65: boolean): void {
    let startX;
    const startY = 380;
    const CIRCLE = 'circle';
    const TEXT = 'text';
    const radius = 10;

    let XCpos = 0;
    let XTpos = 0;

    startX = 210;
    // Show Net
    if (this.chartControl.showNet) {


      // Total Label
      g.append(CIRCLE).attr('cx', startX + 80).attr('cy', startY).attr('r', radius).attr('fill', COLOR_TOTAL);
      g.append(TEXT).attr('x', startX + 100).attr('y', startY + 5).text(this.CHART_COL.TOTAL);

      XCpos = startX + 250;
      XTpos = startX + 270;
      // TAX Label
      g.append(CIRCLE).attr('cx', XCpos).attr('cy', startY).attr('r', radius).attr('fill', COLOR_TAX);
      g.append(TEXT).attr('x', XTpos).attr('y', startY + 5).text(this.CHART_COL.TAX);

    } else {


      startX = 280;

      XCpos = startX;
      XTpos = startX + 20;
      // RPP Label
      g.append(CIRCLE).attr('cx', XCpos).attr('cy', startY).attr('r', radius).attr('fill', COLOR_RPP);
      g.append(TEXT).attr('class', 'text').attr('x', XTpos).attr('y', startY + 5).text(this.CHART_COL.RPP);

      // Bridge Label
      if (IsYoungerThan65) {
        XCpos = XCpos + 80;
        XTpos = XTpos + 80;
        g.append(CIRCLE).attr('cx', XCpos).attr('cy', startY).attr('r', radius).attr('fill', COLOR_BRIDGE);
        g.append(TEXT).attr('x', XTpos).attr('y', startY + 5).text(this.CHART_COL.BRIDGE);
      }


      // SERP Label
      if (isSERP) {
        XCpos = XCpos + 90;
        XTpos = XTpos + 90;
        g.append(CIRCLE).attr('cx', XCpos).attr('cy', startY).attr('r', radius).attr('fill', COLOR_SERP);
        g.append(TEXT).attr('x', XTpos).attr('y', startY + 5).text(this.CHART_COL.SERP);
      }

      if (this.chartControl.IncludeProjection) {
        // CPP Label
        XCpos = XCpos + 85;
        XTpos = XTpos + 85;
        g.append(CIRCLE).attr('cx', XCpos).attr('cy', startY).attr('r', radius).attr('fill', COLOR_CPP);
        g.append(TEXT).attr('x', XTpos).attr('y', startY + 5).text(this.CHART_COL.CPP);

        // OAS Label
        XCpos = XCpos + 75;
        XTpos = XTpos + 75;
        g.append(CIRCLE).attr('cx', XCpos).attr('cy', startY).attr('r', radius).attr('fill', COLOR_OAS);
        g.append(TEXT).attr('x', XTpos).attr('y', startY + 5).text(this.CHART_COL.OAS);

      }

      if (hasRRSP) {
        XCpos = XCpos + 80;
        XTpos = XTpos + 80;
        g.append(CIRCLE).attr('cx', XCpos).attr('cy', startY).attr('r', radius).attr('fill', COLOR_RRSP);
        g.append(TEXT).attr('x', XTpos).attr('y', startY + 5).text(this.CHART_COL.RRSP);

      }
    }

  }

  private getPre65(e: any, isChart: boolean = false): any {

    if (this.chartControl.showNet && this.chartControl.IncludeProjection) {

      return isChart ?
        e.RetirementBenefits.filter(x => x.FormOfPension === this.chartControl.PaymentOptions)[0].TotalPRGPre65Net
        : e.TotalPRGPre65Net;      // showNet:ON  CPP/OAS:ON

    } else if (this.chartControl.showNet && !this.chartControl.IncludeProjection) {

      return isChart ?
        e.RetirementBenefits.filter(x => x.FormOfPension === this.chartControl.PaymentOptions)[0].TotalPRPre65Net
        : e.TotalPRPre65Net;         // showNet:ON  CPP/OAS:OFF

    } else if (!this.chartControl.showNet && this.chartControl.IncludeProjection) {


      return isChart ?
        e.RetirementBenefits.filter(x => x.FormOfPension === this.chartControl.PaymentOptions)[0].TotalPRGPre65Gross
        : e.TotalPRGPre65Gross;      // showNet:OFF  CPP/OAS:ON

    } else {

      return isChart ?
        e.RetirementBenefits.filter(x => x.FormOfPension === this.chartControl.PaymentOptions)[0].TotalPRPre65Gross
        : e.TotalPRPre65Gross;       // showNet:OFF  CPP/OAS:OFF
    }

  }


  private getPost65(e: any, isChart: boolean = false): any {

    if (this.chartControl.showNet && this.chartControl.IncludeProjection) {

      return isChart ?
        e.RetirementBenefits.filter(x => x.FormOfPension === this.chartControl.PaymentOptions)[0].TotalPRGPost65Net
        : e.TotalPRGPost65Net;          // showNet:ON  CPP/OAS:ON

    } else if (this.chartControl.showNet && !this.chartControl.IncludeProjection) {

      return isChart
        ? e.RetirementBenefits.filter(x => x.FormOfPension === this.chartControl.PaymentOptions)[0].TotalPRPost65Net
        : e.TotalPRPost65Net;           // showNet:ON  CPP/OAS:OFF

    } else if (!this.chartControl.showNet && this.chartControl.IncludeProjection) {

      return isChart
        ? e.RetirementBenefits.filter(x => x.FormOfPension === this.chartControl.PaymentOptions)[0].TotalPRGPost65Gross
        : e.TotalPRGPost65Gross;        // showNet:OFF  CPP/OAS:ON

    } else {

      return isChart
        ? e.RetirementBenefits.filter(x => x.FormOfPension === this.chartControl.PaymentOptions)[0].TotalPRPost65Gross
        : e.TotalPRPost65Gross;         // showNet:OFF  CPP/OAS:OFF
    }

  }

  private getPre65Gross(e: any) {

    if (this.chartControl.IncludeProjection) {
      return e.RetirementBenefits.filter(x => x.FormOfPension === this.chartControl.PaymentOptions)[0].TotalPRGPre65Gross;   //  CPP/OAS:ON
    } else {
      return e.RetirementBenefits.filter(x =>
        x.FormOfPension === this.chartControl.PaymentOptions)[0].TotalPRPre65Gross;       // CPP/OAS:OFF
    }
  }

  private getPost65Gross(e: any) {
    if (this.chartControl.IncludeProjection) {
      return e.RetirementBenefits.filter(x =>
        x.FormOfPension === this.chartControl.PaymentOptions)[0].TotalPRGPost65Gross;   // CPP/OAS:ON
    } else {
      return e.RetirementBenefits.filter(x =>
        x.FormOfPension === this.chartControl.PaymentOptions)[0].TotalPRPost65Gross;      // CPP/OAS:OFF
    }
  }

  // Get FormName from FormOfPension value
  private getFormName(e: any): string {
    let ret = '';
    this.applicableFormsOfPension.forEach(d => {

      if (e === d.FormID) {
        ret = d.Name;
        return;
      }
    });
    return ret;
  }

  private wrap(texta, width) {

    texta.each(function () {
      const text = d3.select(this);
      const words = text.text().split(/\s+/).reverse();
      let word;
      let line = [];
      let lineNumber = 0.5;
      const lineHeight = 1.1; // ems
      const y = text.attr('y');
      const dy = parseFloat(text.attr('dy'));
      let tspan = text.text(null).append('tspan').attr('x', 0).attr('y', y).attr('dy', dy + 0.5 + 'em');
      while (word = words.pop()) {
        line.push(word);
        tspan.text(line.join(' '));
        if (tspan.node().getComputedTextLength() > width) {
          line.pop();
          tspan.text(line.join(' '));
          line = [word];
          tspan = text.append('tspan').attr('x', 0).attr('y', y).attr('dy', ++lineNumber * lineHeight + dy + 'em').text(word);
        }
      }
    });
  }

  // open side bar
  openSideBarDefinition(rowDt, event) {
    const retirementBenefit: RetirementBenefits = rowDt;
    openSideBar(retirementBenefit.FormOfPension);
  }

}




