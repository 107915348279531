
import { Component, EventEmitter } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'app-scenario-confirm',
  templateUrl: './scenarioConfirm.html',
  styleUrls: ['./scenarioConfirm.scss']
})
export class ScenarioConfirmlDialogComponent {


  constructor(
    public dialogRef: MatDialogRef<ScenarioConfirmlDialogComponent>) {


  }


  //  onAdd = new EventEmitter();

  // Close Button
  onClickClose(): void {
    this.dialogRef.close();
  }

}


